import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import IncreasedSales from "../../assets/img/why-us/Increased-Sales.png";
import Module1 from "../../assets/img/franchisesupport/Module 1 Orientation and Introduction to Superkirana.png";
import Module2 from "../../assets/img/franchisesupport/Module 2 Store Setup and Design.png";
import Module3 from "../../assets/img/franchisesupport/Module 3 Daily Operations and Inventory Management.png";
import Module4 from "../../assets/img/franchisesupport/Module 4 Customer Service Excellence.png";
import Module5 from "../../assets/img/franchisesupport/Module 5 Financial Management and Reporting.png";
import Module6 from "../../assets/img/franchisesupport/Module 6 Technology and Systems Training.png";
import Module7 from "../../assets/img/franchisesupport/Module 7 Staff Training and Development.png";

import Interior from "../../assets/img/interior/SUPER KIRANA IMAGES.jpg";
import Inventory from "../../assets/img/interior/Inventory Features.png";
import Software from "../../assets/img/interior/Software Features.png";
export default function FranchiseSupportTraining() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing franchise-support-training-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 section-spacing-y py-0 my-0">
              <div className="main-heading main-heading-center">
                <span>Franchiise</span>
                <h2>Franchising Your Business: Becoming a Franchisor</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="modules-card">
                <div className="frnacise-img">
                  <img
                    src={Interior}
                    className="img-fluid"
                    alt="Orientation and Introduction to Superkirana"
                  />
                </div>
                <ul>
                  <li>
                    <p>
                      3D Store’s layout view, store design & theme Preparation.
                    </p>
                  </li>
                  <li>
                    <p>False ceiling of store.</p>
                  </li>
                  <li>
                    <p>White washing in all areas Lighting and wirings.</p>
                  </li>
                  <li>
                    <p>
                      Air conditioner & Wall Fans (Quantity depends on area).
                    </p>
                  </li>

                  <li>
                    <p>
                      Visi Freezer and Deep Freezer for Cold drinks and dairy
                      products.
                    </p>
                  </li>
                  <li>
                    <p>
                      Racks, Trolley, and buckets, Billing Counter, Barcode
                      Scanner, and printer.
                    </p>
                  </li>
                  <li>
                    <p>
                      All items come from the company through courier from
                      SuperKirana.
                    </p>
                  </li>
                  <li>
                    <p>All items come with Brand Warranty.</p>
                  </li>
                  <li>
                    <p>CCTV Cameras for enhanced security.</p>
                  </li>
                  <li>
                    <p> Customer seating area for comfort..</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="modules-card">
                <div className="frnacise-img">
                  <img
                    src={Inventory}
                    className="img-fluid"
                    alt=" Store Setup and Design"
                  />
                </div>
                <p>
                  <strong>
                    Grocery, Household Items, Food & Snacks, Dairy Products,
                    Ready-To-Eat Items & Frozen Food, Fruits & Vegetables, Home
                    And Kitchen, and many other categories
                  </strong>
                </p>
                <ul>
                  <li>
                    <p>
                      1000+ Brands and 20,000+ Products 20% to 30% Average{" "}
                      <b>Margin</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      Largest Network of stores across India Easy Distribution
                      and Warehousing chain Easy and efficient logistics
                    </p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      Wide range of products and brands Well-trained staff and
                      management Attractive Offers and discounts BUY ONE GET ONE
                      Offers
                    </p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      Easy Return of expired products Seasonal Discounts and
                      Festive Offers Efficient Supplier Management System
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="modules-card">
                <div className="frnacise-img">
                  <img
                    src={Software}
                    className="img-fluid"
                    alt="Daily Operations and Inventory Management"
                  />
                </div>
                <ul>
                  <li>
                    <p>Unlimited User</p>
                  </li>
                  <li>
                    <p>Unlimited Training & Support 5000 SMS Alerts</p>
                  </li>
                  <li>
                    <p>
                      Pricing Management – Change MRP Product Wise Sale Reports
                      & Purchase Report
                    </p>
                  </li>
                  <li>
                    <p>Use Existing Barcodes</p>
                  </li>
                  <li>
                    <p>Stock Report & Import Existing Stock By Excel Sheet</p>
                  </li>
                  <li>
                    <p>Generate & Print BarcodePurchase Return Report</p>
                  </li>

                  <li>
                    <p>Daily & Monthly Reports for All</p>
                  </li>
                  <li>
                    <p>Sale Return & Exchange Reports</p>
                  </li>

                  <li>
                    <p>GST Tax Report & Many More</p>
                  </li>
                  <li>
                    <p>Excel Export of All Reports</p>
                  </li>
                  <li>
                    <p>Past Bill Editing</p>
                  </li>
                  <li>
                    <p>Real-time Inventory Tracking</p>
                  </li>
                  <li>
                    <p>Automatic Reordering Alerts</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
