import InnerBanner from "../Components/InnerBanner/InnerBanner";
import AboutUs from "../Components/TheBrand/AboutUs/AboutUs";
import CoreValues from "../Components/TheBrand/CoreValues/CoreValues";
import MissionVision from "../Components/TheBrand/MissionVision/MissionVision";
import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
export default function TheBrand() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <AboutUs />
      <CoreValues />
      {/* <MissionVision /> */}
    </>
  );
}
