import { Link } from "react-router-dom";
import "./ServicesSection.css";
import { FaHandshake } from "react-icons/fa";
import SupportTraining from "../../../assets/img/services/Support-Training.png";
import SupplyChain from "../../../assets/img/services/supply-chain.png";
import InventoryManagement from "../../../assets/img/services/inventory-management.png";
import Marketing from "../../../assets/img/services/Marketing.png";
import LeftImg from "../../../assets/img/services/592.png";
import Superkiranavideo from "../../../assets/img/services/SUPERKIRANA.mp4";
import VideoPoster from "../../../assets/img/services/mustaq khan.png";
export default function ServicesSection() {
  return (
    <section className="services-section section-spacing">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 mb-4 mb-lg-0">
            {/* <img src={LeftImg} className="img-fluid mb-2 left-img" alt="" /> */}

            <video className="mt-0 pt-0 w-100 h-auto border-radius" src={Superkiranavideo} type="video/mp4" poster={VideoPoster} controls autoPlay>
              Your browser does not support the video tag.
            </video>

            <div className="main-heading">
              <span>Services</span>
              <h2>
                Superkirana franchise support
              </h2>
            </div>
            <p>
              Superkirana ensures that its franchise partners are taken care of
              in providing all the necessary support & training. By using
              technology & a strong infrastructure that can give the partner all
              the resources they need to run their business effectively,
              Superkirana Franchise partners stand a chance to achieve good
              profits.
            </p>
            <Link to="/franchise-support-and-training" className="theme-btn mt-4">
              Read More
            </Link>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="service-card">
                  <div className="icon">
                    <img
                      src={SupportTraining}
                      className="img-fluid"
                      alt="Franchise Support & Training"
                    />
                  </div>
                  <div className="content">
                    <h4>Franchise Support & Training</h4>
                    <p>
                      A dedicated franchise support team is available to assist
                      franchisees with any issues they may encounter, providing
                      guidance, training and ensuring that their operations run
                      smoothly.
                    </p>
                  </div>
                </div>
                <div className="service-card">
                  <div className="icon">
                    <img
                      src={SupplyChain}
                      className="img-fluid"
                      alt="Supply Chain Management"
                    />
                  </div>
                  <div className="content">
                    <h4>Supply Chain Management</h4>
                    <p>
                      Streamline your inventory and logistics with efficient
                      supply chain solutions to ensure smooth and timely
                      deliveries with our supply chain solutions & inventory
                      flow of products.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-lg-4 pt-lg-3">
                <div className="service-card">
                  <div className="icon">
                    <img
                      src={InventoryManagement}
                      className="img-fluid"
                      alt="Inventory Management"
                    />
                  </div>
                  <div className="content">
                    <h4>Inventory Management</h4>
                    <p>
                      A fully integrated inventory management system to provide
                      extensive inventory of grocery items, fresh & frozen
                      goods, snacks, beverages & a variety of household
                      products.
                    </p>
                  </div>
                </div>
                <div className="service-card">
                  <div className="icon">
                    <img
                      src={Marketing}
                      className="img-fluid"
                      alt="Marketing & Promotions"
                    />
                  </div>
                  <div className="content">
                    <h4>Marketing & Promotions</h4>
                    <p>
                      Our marketing strategy includes national-level branding
                      campaigns, Digital marketing strategies include social
                      media, online ads, and influencer partnerships. Local
                      store & Instore promotions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
