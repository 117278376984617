import InnerBanner from "../Components/InnerBanner/InnerBanner";
import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import img from "../assets/img/no-image.jpg";
import { Link } from "react-router-dom";
export default function BlogDetail() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing blog-detail-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="text-center mb-4">
                <h1>Title</h1>
                <p>29 Aug, 2024</p>
              </div>
              <div className="image">
                <img src={img} className="img-fluid" alt="" />
              </div>
              <div className="content">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Molestias soluta blanditiis culpa obcaecati dolores
                  reprehenderit harum. Corporis consequuntur eum, repellat
                  libero error minima voluptates sunt ullam tenetur ad aliquam
                  animi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
