import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../Components/InnerBanner/InnerBanner";
export default function PrivacyPolicy() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing static-page privacy-policy-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Privacy Policy</span>
                {/* <h2>Heading</h2> */}
              </div>
              <p>
                SuperKirana is committed to protecting your privacy and ensuring
                the security of your personal information. This Privacy Policy
                outlines how we collect, use, and protect your information when
                you visit our website and use our services. By accessing our
                website, you agree to the terms of this Privacy Policy
              </p>
              <h4>Information We Collect</h4>
              <p>
                <b>Personal Information:</b> When you sign up for our services,
                create an account, or contact us, we may collect personal
                information such as your name, email address, phone number,
                store location, and other relevant details.
              </p>
              <p>
                <b>Non-Personal Information:</b> We may also collect
                non-personal information such as your browser type, IP address,
                device type, and browsing behavior through the use of cookies
                and similar technologies.
              </p>
              <h4>How We Use Your Information</h4>
              <p>
                <b>Providing Services:</b> We use your personal information to
                provide, maintain, and improve our services, including
                processing transactions, communicating with you, and offering
                customer support.
              </p>
              <p>
                <b>Personalization:</b> We may use your information to
                personalize your experience on our website, including showing
                you content and recommendations tailored to your interests.
              </p>
              <p>
                <b>Marketing and Promotions:</b> With your consent, we may use
                your information to send you promotional materials, newsletters,
                and updates about our services. You can opt out of these
                communications at any time.
              </p>
              <p>
                <b>Analytics:</b> We use non-personal information to analyze
                website usage and performance, improve our services, and
                understand user behavior
              </p>
              <h4>How We Protect Your Information</h4>
              <p>
                <b>Security Measures:</b> We implement a variety of security
                measures to protect your personal information from unauthorized
                access, use, or disclosure. These measures include encryption,
                secure servers, and access controls.
              </p>
              <p>
                <b>Data Retention:</b> We retain your personal information for
                as long as necessary to fulfill the purposes outlined in this
                Privacy Policy, unless a longer retention period is required or
                permitted by law.
              </p>
              <h4>Sharing Your Information</h4>
              <p>
                <b>Third-Party Service Providers:</b> We may share your
                information with third-party service providers who assist us in
                providing our services, such as payment processors, marketing
                partners, and IT support. These providers are required to
                protect your information and use it only for the purposes for
                which it was disclosed.
              </p>
              <p>
                <b>Legal Requirements:</b> We may disclose your information if
                required to do so by law or in response to valid requests by
                public authorities, such as a court or government agency.
              </p>
              <p>
                <b>Business Transfers:</b> In the event of a merger,
                acquisition, or sale of all or part of our business, your
                information may be transferred as part of the transaction. We
                will notify you of any such change in ownership or control of
                your personal information.
              </p>
              <h4>Your Choices and Rights</h4>
              <p>
                <b>Access and Update:</b> You have the right to access and
                update your personal information at any time by logging into
                your account or contacting us directly.
              </p>
              <p>
                <b>Opt-Out:</b> You can opt out of receiving promotional
                communications from us by following the unsubscribe instructions
                provided in the communication or by contacting us.
              </p>
              <p>
                <b>Data Deletion:</b> You have the right to request the deletion
                of your personal information, subject to certain legal
                exceptions. To request deletion, please contact us.
              </p>
              <h4>Cookies and Tracking Technologies</h4>
              <p>
                <b>Cookies:</b> We use cookies and similar tracking technologies
                to collect non-personal information about your browsing behavior
                and preferences. You can manage your cookie preferences through
                your browser settings.
              </p>
              <p>
                <b>Analytics Tools:</b> We use third-party analytics tools, such
                as Google Analytics, to collect and analyze non-personal
                information about your use of our website. These tools use
                cookies and other tracking technologies to gather information
                and provide insights.
              </p>
              <h4>Children's Privacy</h4>
              <p>
                Our website and services are not intended for children under the
                age of 13. We do not knowingly collect personal information from
                children under 13. If we become aware that we have inadvertently
                collected personal information from a child under 13, we will
                take steps to delete the information as soon as possible.
              </p>
              <h4>Changes to This Privacy Policy</h4>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. Any changes will
                be posted on this page, and the updated policy will be effective
                immediately upon posting. We encourage you to review this
                Privacy Policy periodically to stay informed about how we are
                protecting your information
              </p>
              <h4>Contact Us</h4>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us:
              </p>
              <p>
                <b>Address:</b> B- 2, Vatika Mindscapes,NH- 2 Mathura Road Faridabad, Haryana- 121003,INDIA.
              </p>
              <p>
                Phone: <a href="tel:+919205781326"> +91 92057 81326</a>.{" "}
                {/* <a href="tel:+919205781322">+91 92057 81322</a>. */}
              </p>
              <p>
                <b>Email:</b>{" "}
                <a href="mailto:info@superkirana.com">info@superkirana.com.</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
