import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../Components/InnerBanner/InnerBanner";

import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Button } from "react-bootstrap";
import Dynamic from "../assets/img/career/Dynamic Work Environment.png";
import Growth from "../assets/img/career/Growth Opportunities.png";
import Impactful from "../assets/img/career/Impactful Work.png";
import Inclusive from "../assets/img/career/Inclusive Culture.png";
import Career from "../assets/img/career/carrer image .jpg";
import ContactForm from "../Pages/ContactForm/ContactForm";
export default function Careers() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing careers-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5">
              <img src={Career} className="img-fluid h-100" alt="Career Opportunities" />
            </div>
            <div className="col-lg-7">
              <div className="main-heading">
                <span>Career Opportunities</span>
                <h2 className="mb-0">Join the SuperKirana Team Where Careers and Growth Thrive</h2>
                <p>
                  At Superkirana, we are passionate about revolutionizing the
                  way people shop for their everyday needs. Our mission is to
                  create a seamless shopping experience by blending technology,
                  quality products, and exceptional customer service. We believe
                  in empowering our employees to grow, innovate, and contribute
                  to a shared vision of success.
                </p>
              </div>
              <h5>Why Work with Us?</h5>
              <div className="why-work-card">
                <div className="icon">
                  <img src={Dynamic} className="career-below-icon" alt="Dynamic Work Environment" />
                </div>
                <div className="conytent">
                  <h6>Dynamic Work Environment:</h6>
                  <p>
                    Be part of a fast-paced, innovative team where every day
                    brings new challenges and opportunities.
                  </p>
                </div>
              </div>
              <div className="why-work-card">
                <div className="icon">
                  <img src={Growth} className="career-below-icon" alt="Growth Opportunities" />
                </div>
                <div className="conytent">
                  <h6>Growth Opportunities:</h6>
                  <p>
                    We value our employees' professional growth and offer
                    opportunities for career advancement, training, and skill
                    development.
                  </p>
                </div>
              </div>
              <div className="why-work-card">
                <div className="icon">
                  <img src={Inclusive} className="career-below-icon" alt="Inclusive Culture" />
                </div>
                <div className="conytent">
                  <h6>Inclusive Culture:</h6>
                  <p>
                    We foster a collaborative and inclusive workplace where
                    everyone’s voice is heard and respected.
                  </p>
                </div>
              </div>
              <div className="why-work-card">
                <div className="icon">
                  <img src={Impactful} className="career-below-icon" alt="Impactful Work" />
                </div>
                <div className="conytent">
                  <h6>Impactful Work:</h6>
                  <p>
                    At Superkirana, your work will directly impact the lives of
                    our customers, helping them access quality products
                    conveniently.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 section-spacing-y pb-3">
              <div className="main-heading main-heading-center">
                <span>Current Openings</span>
                <p>Please fill out the form below to explore potential job openings, submit your application, or express your interest in career advancement.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="job-card">
                <h4>Human Resource Manager (HRM)</h4>
                <div className="content">
                  <div className="box">
                    <b>Location:</b>
                    <p>Okhla, Delhi</p>
                  </div>
                  <div className="box">
                    <b>Experience:</b>
                    <p>Experience</p>
                  </div>
                  <b>Responsibilities:</b>
                  <ul>
                    <li>
                      <p>
                        Develop and implement HR strategies and initiatives
                        aligned with the overall business strategy
                      </p>
                    </li>
                    <li>
                      <p>Manage the recruitment and selection process</p>
                    </li>
                    <li>
                      <p>
                        Support current and future business needs through the
                        development, engagement, motivation, and preservation of
                        human capital
                      </p>
                    </li>
                    <li>
                      <p>
                        Oversee and manage a performance appraisal system that
                        drives high performance
                      </p>
                    </li>
                    <li>
                      <p>
                        Ensure legal compliance throughout human resource
                        management
                      </p>
                    </li>
                  </ul>
                  <b>Qualifications:</b>
                  <ul>
                    <li>
                      <p>
                        Proven working experience as an HR manager or other HR
                        executive
                      </p>
                    </li>
                    <li>
                      <p>People-oriented and results-driven</p>
                    </li>
                    <li>
                      <p>
                        Demonstrable experience with human resources metrics
                      </p>
                    </li>
                    <li>
                      <p>Knowledge of HR systems and databases</p>
                    </li>
                    <li>
                      <p>
                        Excellent active listening, negotiation, and
                        presentation skills
                      </p>
                    </li>
                  </ul>
                  <a href="mailto:info@mathillsgroup.com" className="theme-btn">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-lg-0 mt-4">
              <div className="main-heading">
                <h2>Send Us a Message</h2>
              </div>
              <div className="enquiry-form row">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
