import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import Approval from "../../assets/img/application/approved.png";
import EfficientLogistics from "../../assets/img/supplychainmanagement/Efficient Logistics and Delivery.png";
import EndtoEnd from "../../assets/img/supplychainmanagement/End-to-End Backend Operations.png";
import MultiBrand from "../../assets/img/supplychainmanagement/Multi-Brand Grocery Sourcing.png";
import SeamlessFranchise from "../../assets/img/supplychainmanagement/Seamless Franchise Experience.png";
import Storesetup from "../../assets/img/supplychainmanagement/Store Setup Support.png";
import TechnologyDriven from "../../assets/img/supplychainmanagement/Technology-Driven Inventory Management.png";
import SupplyChaninManagment from "../../assets/img/supplychainmanagement/supply chain management image.jpg";
export default function SupplyChainManagement() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing supply-chain-management-page">
        <div className="container-fluid">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-6">
              <div className="main-heading">
                <span>Supply Chain Management</span>
                <h2>Efficient Management For Your Franchise Store</h2>
                <p className="mb-3">
                  At Super Kirana, Mathills Private Limited serves as the
                  backbone of its supply chain management (SCM), offering
                  comprehensive solutions that streamline grocery sourcing and
                  distribution. With Mathills managing multiple grocery brands,
                  Super Kirana stores are assured of a well-organized supply
                  chain that guarantees product availability, quality, and
                  efficiency.
                </p>
                <p>
                  For those looking to open a Super Kirana franchise, Mathills
                  not only manages the supply chain but also offers a complete
                  suite of services to help franchisees successfully set up and
                  operate their stores.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={SupplyChaninManagment}
                className="img-fluid border-radius"
                alt="Supply Chanin Managment"
              />
            </div>
          </div>
          <div className="row section-spacing-y pb-0">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Cost-Effective Procurement</span>
                <h2>
                  Key Benefits of Super Kirana Supply Chain Management
                </h2>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="benifits-card">
                <img src={MultiBrand} className="img-fluid" alt="Multi-Brand Grocery Sourcing" />
                <h4>Multi-Brand Grocery Sourcing</h4>
                <p>
                  Mathills manages a wide array of grocery brands, ensuring that
                  every Super Kirana store is stocked with popular and essential
                  products across categories, from staples to fresh produce.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="benifits-card">
                <img src={EndtoEnd} className="img-fluid" alt="End-to-End Backend Operations" />
                <h4>End-to-End Backend Operations</h4>
                <p>
                  Super Kirana franchise owners can rely on Mathills to handle
                  all backend operations, from supplier coordination to
                  inventory management, allowing them to focus on customer
                  service and store operations.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="benifits-card">
                <img src={Storesetup} className="img-fluid" alt="Store Setup Support" />
                <h4>Store Setup Support</h4>
                <p>
                  Super Kirana provides franchisees with the best services for
                  setting up their stores, including expert guidance on store
                  layout, inventory planning, and product placement to optimize
                  customer experience.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="benifits-card">
                <img src={EfficientLogistics} className="img-fluid" alt="Efficient Logistics and Delivery" />
                <h4>Efficient Logistics and Delivery</h4>
                <p>
                  Mathills' robust logistics system ensures that grocery
                  products are delivered to franchise stores in a timely and
                  cost-effective manner, maintaining product freshness and
                  availability.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="benifits-card">
                <img src={TechnologyDriven} className="img-fluid" alt="Technology-Driven Inventory Management" />
                <h4>Technology-Driven Inventory Management</h4>
                <p>
                  With Mathills’ advanced inventory management systems,
                  franchisees can maintain optimal stock levels, reducing waste
                  and ensuring they never run out of popular products.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="benifits-card">
                <img src={SeamlessFranchise} className="img-fluid" alt="Seamless Franchise Experience" />
                <h4>Seamless Franchise Experience</h4>
                <p>
                  Mathills’ expertise in SCM ensures a hassle-free experience
                  for franchise owners, offering full support in managing
                  backend functions like procurement, inventory tracking, and
                  quality control.
                </p>
              </div>
            </div>
            <div className="col-12">
              <p>
                By partnering with Mathills Private Limited, Super Kirana
                franchisees benefit from a proven supply chain that not only
                keeps their shelves stocked but also provides comprehensive
                support in running their stores. This ensures franchisees can
                focus on growing their business while Mathills takes care of the
                rest.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
