import { IoIosCall } from "react-icons/io";
import { IoMail } from "react-icons/io5";
import "./MainFooter.css";
import moment from "moment";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import Logo from "../../assets/img/logo/superkirana-logo.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
export default function MainFooter() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="footer-section">
      <section className="contact-bar section-spacing">
        <div className="container-fluid ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="left-section">
                <div className="">
                  <h4>Take the Next Step with Superkirana</h4>
                  <p>
                    Ready to transform your Kirana store into a modern retail
                    powerhouse? Contact us now for a free consultation and
                    personalized growth plan tailored to your store's needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <ul>
                <li>
                  <a href="mailto:info@mathillsgroup.com" className="theme-btn btn-footer-top-contact">
                    Email us at info@mathillsgroup.com
                  </a>
                </li>
                <li>
                  <a href="tel:+919205781326" className="theme-btn btn-footer-top-contact">
                    Call +91 92057 81326
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <footer className="section-spacing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <Link to="/">
                <img
                  src={Logo}
                  className="img-fluid footer-logo"
                  alt="Superkirana Logo"
                />
              </Link>
              <p className="text-justify">
                Join the Superkirana Revolution <br />
                Transform Your Store & Boost Your Profits! Empowering & Turning
                your Kirana Stores into a Super Retail Hub
              </p>
              <ul className="sm-links">
                <li>
                  <a className="facebook-bg" href="https://www.facebook.com/profile.php?id=61563814711964" target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a className="instagram-bg" href="https://www.instagram.com/superkirana_official/?hl=en" target="_blank" rel="noopener noreferrer">
                    <FaSquareInstagram />
                  </a>
                </li>
                <li>
                  <a className="linkdln-bg" href="https://www.linkedin.com/company/104161632/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                  </a>
                </li>
                <li>
                  <a className="youtube-bg" href="https://www.youtube.com/@Superkirana" target="_blank" rel="noopener noreferrer">
                    <FaYoutube />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-5 col-md-6 ps-lg-5">
              <h4>Useful Links</h4>
              <ul className="links link-top">
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <Link to="/faqs">FAQs</Link>
                </li>
                <li>
                  <Link to="/news-and-media">News & Media</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/brands">Brands</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms and Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 contact-us-page">
              {/* <h4>Services</h4>
              <ul className="links">
                <li>
                  <Link to="/franchise-support-and-training">
                    Franchise Support & Training
                  </Link>
                </li>
                <li>
                  <Link to="/supply-chain-management">
                    Supply Chain Management
                  </Link>
                </li>
                <li>
                  <Link to="/inventory-management">Inventory Management</Link>
                </li>
                <li>
                  <Link to="/marketing-and-promotions">
                    Marketing & Promotions
                  </Link>
                </li>
              </ul> */}


              <h4 className="mt-md-4 mt-lg-0 mb-4">Contact Us</h4>
              <div className="conatct-link">
                <div className="box box-icon-footer">
                  <div className="icon icon-footer">
                    <IoMail />
                  </div>
                  <div className="links">
                    <a className="mt-0 pt-0" href="mailto:info@mathillsgroup.com">
                      info@mathillsgroup.com
                    </a>
                  </div>
                </div>
                <div className="box box-icon-footer">
                  <div className="icon icon-footer">
                    <IoIosCall />
                  </div>
                  <div className="links">
                    <a href="tel:+919205781326">+91 92057 81326</a>
                  </div>
                </div>
                <div className="box box-icon-footer">
                  <div className="icon icon-footer">
                    <FaLocationDot />
                  </div>
                  <div className="links">
                    <a href="javascript:void(0)" rel="noopener noreferrer">
                      B- 2, Vatika Mindscapes,
                      <br />
                      NH- 2 Mathura Road Faridabad,
                      <br /> Haryana - 121 003, INDIA
                      {/* Vatika Mindscape Building, 12th Floor, Tower A,
                      <br /> Business Plus, Main Mathura Road, Near Sarai Toll
                      Plaza,<br /> Faridabad INDIA. */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 text-center mt-4">
              <p className="disclaimer-text">
                Disclaimer: The information on this website about Superkirana’s franchise
                opportunities is for general information only. Before deciding
                to invest, please do your own research and talk to a
                professional. Superkirana is not responsible for any decisions
                you make based on the information provided here.
              </p>
            </div> */}
          </div>
        </div>
      </footer>
      <section className="section-spacing-x copyright-section">
        <div className="container-fluid">
          <div className="py-3 row flex-column-reverse flex-lg-row justify-content-lg-between">
            <div className="col-12 text-center">
              &copy; {moment().format("YYYY")} Superkirana.{" "}
              <br className="d-block d-lg-none" /> All Rights Reserved.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
