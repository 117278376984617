import AdvancedTechnology from "../../../assets/img/core-values/AdvancedTechnology.png";
import BrandingMarketing from "../../../assets/img/core-values/BrandingMarketing.png";
import CustomerCentric from "../../../assets/img/core-values/CustomerCentric.png";
import ModernStore from "../../../assets/img/core-values/ModernStore.png";
import WideProduct from "../../../assets/img/core-values/WideProduct.png";
import NoImg from "../../../assets/img/no-image.jpg";
import "./CoreValues.css";
export default function CoreValues() {
  return (
    <section className="section-spacing pt-0 core-values-section">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="main-heading main-heading-center">
              <span>Defining SuperKirana</span>
              <h2>
              Comprehensive Features That Make SuperKirana Stand Out
              </h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="core-values-card">
              <div className="icon">
                <img
                  src={ModernStore}
                  className="img-fluid"
                  alt="Modern Store Design"
                />
              </div>
              <div className="content">
                <h4>Modern Store Design</h4>
                <p>
                  SuperKirana stores are designed with the customer in mind. The
                  layout is optimized for easy navigation, product visibility,
                  and efficient use of space.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="core-values-card">
              <div className="icon">
                <img
                  src={AdvancedTechnology}
                  className="img-fluid"
                  alt="Advanced Technology Integration"
                />
              </div>
              <div className="content">
                <h4> Advanced Technology Integration</h4>
                <p>
                  SuperKirana integrates advanced technology solutions,
                  including Point of Sale (POS) systems, inventory management
                  software, and digital payment options and CRM.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="core-values-card">
              <div className="icon">
                <img
                  src={WideProduct}
                  className="img-fluid"
                  alt="Wide Product Range"
                />
              </div>
              <div className="content">
                <h4>Wide Product Range</h4>
                <p>
                  SuperKirana stores can stock a wide variety of items,
                  including groceries, household essentials, personal care
                  products, and more to increase the average basket size.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="core-values-card">
              <div className="icon">
                <img
                  src={BrandingMarketing}
                  className="img-fluid"
                  alt="Branding and Marketing"
                />
              </div>
              <div className="content">
                <h4>Branding and Marketing</h4>
                <p>
                  SuperKirana provides its franchisees with professional
                  branding and marketing support. This includes everything from
                  store signage and promotional materials to digital marketing
                  campaigns.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="core-values-card">
              <div className="icon">
                <img
                  src={CustomerCentric}
                  className="img-fluid"
                  alt="Customer-Centric Approach"
                />
              </div>
              <div className="content">
                <h4>Customer-Centric Approach</h4>
                <p>
                  Loyalty programs and personalized promotions are also part of
                  the SuperKirana concept, encouraging repeat business and
                  building long-term customer relationships.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
