import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
export default function InvestmentFinancials() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing investment-financials-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Investment & Financials</span>
                <h2>Detailed Guide to Investment Opportunities and Financial Planning</h2>
                <p>
                  This plan outlines the initial investment, ongoing expenses,
                  revenue projections, and profitability analysis to help
                  franchisees understand the financial commitments and potential
                  returns associated with the business.
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="main-heading">
                <span>Initial Investment Requirements</span>
                {/* <h2>Heading</h2> */}
                <p className="mt-3">
                  The initial investment required to open a Superkirana
                  franchise includes both fixed and variable costs. Below is a
                  breakdown of the key investment components:
                </p>
              </div>
              <ul>
                <li>
                  <p>
                    <b>Franchise Fee:</b>
                    This one-time fee grants the franchisee the right to operate
                    under the Superkirana brand.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Store Build-Out and Renovation:</b>
                    Costs associated with site selection, leasehold
                    improvements, and store setup.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Equipment and Fixtures:</b>
                    Purchase of necessary equipment, fixtures, and furniture for
                    the store.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Inventory:</b>
                    Initial stock of products to be sold in the store.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Marketing and Advertising:</b>
                    Pre-opening and grand opening marketing expenses.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Working Capital:</b>
                    Funds required to cover operating expenses during the
                    initial months of operation.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Miscellaneous Costs:</b>
                    Other expenses such as permits, insurance, and legal fees.
                  </p>
                </li>
              </ul>
              <b>
                The total initial investment is estimated to range between INR
                3Lac and INR 10Lac, depending on location, store size, and
                Franchise Model Selection.
              </b>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
