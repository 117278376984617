import { FaQuoteLeft } from "react-icons/fa";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "../BlogSection/BlogSection.css";
import { FaStar } from "react-icons/fa6";

// import required modules
import { Pagination } from "swiper/modules";

const data = [
  {
    id: 1,
    name: "krish jha",
    description:
      "I absolutely love shopping at this grocery store! The variety of fresh produce is incredible, and everything is always well-stocked and organized. The quality of the fruits, vegetables is top-notch, and I can always find exactly what I need. The staff is friendly, welcoming, and always ready to help, which makes the shopping experience even more enjoyable. I also appreciate how clean and well-maintained the store is.",
  },
  {
    id: 2,
    name: "Sonu Kumar",
    description:
      "Superkirana Mart is your go-to for quality, affordability, and convenience. With a wide range of fresh products and everyday essentials, it offers a seamless shopping experience. The franchise model is equally impressive, providing strong support and a proven business model for franchisees. Whether you're a shopper or a potential business owner, Superkirana delivers reliability and satisfaction every time!",
  },
  {
    id: 3,
    name: "Mohammad Seraj",
    description: "Supermarket thank you so nice main Saudi Arabia se Riyad",
  },
];

export default function Testimonial() {
  return (
    <section className="blog-section section-spacing mb-0 pb-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="main-heading main-heading-center">
              <span>Testimonial</span>
              <h2>Hear from Our Customers</h2>
            </div>
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              loop={true}
              breakpoints={{
                880: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Pagination]}
              className="mySwiper pb-5 pb-lg-0"
            >
              {data.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className="content px-3 py-5 position-relative border-radius text-justify border testimonial-box">
                    <p className="my-3">{item.description}</p>
                    <div className="position-absolute top-0 my-3 opacity-25">
                      <FaQuoteLeft className="fs-1 quote-bg" />
                    </div>
                    <p className="text-warning fs-4 text-center mb-2 pb-0">
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </p>
                    <p className="mt-1 text-center">
                      <strong>{item.name}</strong>
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
