import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../Components/InnerBanner/InnerBanner";
import IncreasedSales from "../assets/img/why-us/Increased-Sales.png";
import Step1 from "../assets/img/getstarted/Step 1 Registration and Token Payment.png";
import Step2 from "../assets/img/getstarted/Step 2 Store Location Assessment and Site Visit.png";
import Step3 from "../assets/img/getstarted/Step 3 Store Branding and Layout Design.png";
import Step4 from "../assets/img/getstarted/Step 4 Credit Onboarding and Financial Arrangements.png";
import Step5 from "../assets/img/getstarted/Step 5 Store Renovation and Setup.png";
import Step6 from "../assets/img/getstarted/Step 6 Pre-Launch Marketing Campaign.png";
import Step7 from "../assets/img/getstarted/Step 7 Final Inspection and Approval.png";
import Step8 from "../assets/img/getstarted/Step 8 Grand Opening Day.png";
export default function GetStarted() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing get-started-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center mb-4">
                <span>Begin Your Transformation</span>
                <h2>Start Strong: Your Guide to Getting Going</h2>
                <p>
                  Here is a step-by-step guide of what the Superkirana team does
                  after you register with a token amount, up until your store’s
                  grand opening.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="get-card">
                <img src={Step1} className="img-fluid" alt="Registration and Token Payment" />
                <h4>Step 1: Registration and Token Payment</h4>
                <p>
                  After paying the token amount, you secure your place in the
                  Superkirana franchise. The support team confirms your
                  registration and provides a timeline for next steps. You will
                  submit required documents for verification.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="get-card">
                <img src={Step2} className="img-fluid" alt="Store Location Assessment and Site Visit" />
                <h4>Step 2: Store Location Assessment and Site Visit</h4>
                <p>
                  The Superkirana team visits the store location to assess its
                  suitability and gather details on any necessary
                  customizations. The space is evaluated to ensure the best
                  layout for customer flow and product placement.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="get-card">
                <img src={Step3} className="img-fluid" alt="Store Branding and Layout Design" />
                <h4>Step 3: Store Branding and Layout Design</h4>
                <p>
                  Our branding team designs the store's layout and branding,
                  including signage, product displays, and external branding.
                  After your approval, we order the materials necessary for
                  renovation.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="get-card">
                <img src={Step4} className="img-fluid" alt="Credit Onboarding and Financial Arrangements" />
                <h4>Step 4: Credit Onboarding and Financial Arrangements</h4>
                <p>
                  Superkirana activates the credit facility, allowing you to
                  access stock without upfront costs. We then place your initial
                  stock order as per the selected franchise model.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="get-card">
                <img src={Step5} className="img-fluid" alt="Store Renovation and Setup" />
                <h4>Step 5: Store Renovation and Setup </h4>
                <p>
                  The operations team coordinates the renovation of your store
                  based on the approved layout. Shelving, display units, and
                  technology such as POS systems are installed. Staff training
                  is provided to ensure smooth operations.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="get-card">
                <img src={Step6} className="img-fluid" alt="Pre-Launch Marketing Campaign" />
                <h4>Step 6: Pre-Launch Marketing Campaign </h4>
                <p>
                  Superkirana provides branded marketing materials, and we help
                  generate buzz for your store with pre-launch promotions. We
                  also ensure your store has a digital presence with listings on
                  e-commerce platforms.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="get-card">
                <img src={Step7} className="img-fluid" alt="Final Inspection and Approval" />
                <h4>Step 7: Final Inspection and Approval </h4>
                <p>
                  A final inspection ensures that everything is ready for the
                  launch, including inventory, technology, and branding. Once
                  everything is set, the store is approved for the grand
                  opening.
                </p>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="get-card">
                <img src={Step8} className="img-fluid" alt="Grand Opening Day" />
                <h4>Step 8: Grand Opening Day </h4>
                <p>
                  The store opens with a grand launch event, featuring exclusive
                  opening day deals and local marketing support. Superkirana
                  provides ongoing operational and marketing support after the
                  opening.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
