import "./BrandSection.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import amul from "../../../assets/img/brands/amul.png";
import bajaj from "../../../assets/img/brands/bajaj.png";
import bikaji from "../../../assets/img/brands/bikaji.png";
import britannia from "../../../assets/img/brands/britannia.png";
import cadbury from "../../../assets/img/brands/cadbury.png";
import Catch from "../../../assets/img/brands/catch.png";
import cocacola from "../../../assets/img/brands/coca-cola.png";
import colgate from "../../../assets/img/brands/colgate.png";
import dettol from "../../../assets/img/brands/dettol.png";
import fortune from "../../../assets/img/brands/fortune.png";
import haldiram from "../../../assets/img/brands/haldiram's.png";
import itc from "../../../assets/img/brands/itc.png";
import johnson from "../../../assets/img/brands/johnson.png";
import loreal from "../../../assets/img/brands/loreal.png";
import mdh from "../../../assets/img/brands/mdh.png";
import nestle from "../../../assets/img/brands/nestle.png";
import pg from "../../../assets/img/brands/p&g.png";
import parle from "../../../assets/img/brands/parle.png";
import patanjali from "../../../assets/img/brands/patanjali.png";
import pepsi from "../../../assets/img/brands/pepsi.png";
import philips from "../../../assets/img/brands/philips.png";
import skybags from "../../../assets/img/brands/skybags.png";
export default function BrandSection() {
  return (
    <section className="brand-section section-spacing">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="main-heading main-heading-center">
              <span>Brand Associations</span>
              <h2>
                As a Superkirana Franchise Partner you get to sell products from
                all leading brands.
              </h2>
            </div>
            <Swiper
              slidesPerView={2}
              slidesPerGroup={2}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              //   loop={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 5,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 8,
                  slidesPerGroup: 3,
                  spaceBetween: 50,
                },
              }}
              modules={[Pagination]}
              className="mySwiper pb-4 mt-4 pt-lg-4">
              <SwiperSlide>
                <img src={amul} className="img-fluid" alt="amul" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={bajaj} className="img-fluid" alt="bajaj" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={bikaji} className="img-fluid" alt="bikaji" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={britannia} className="img-fluid" alt="britannia" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={cadbury} className="img-fluid" alt="cadbury" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Catch} className="img-fluid" alt="catch" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={cocacola} className="img-fluid" alt="cocacola" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={colgate} className="img-fluid" alt="colgate" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={dettol} className="img-fluid" alt="dettol" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={fortune} className="img-fluid" alt="fortune" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={haldiram} className="img-fluid" alt="haldiram" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={itc} className="img-fluid" alt="itc" />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={johnson}
                  className="img-fluid"
                  alt="johnson & johnson"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src={loreal} className="img-fluid" alt="loreal" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={mdh} className="img-fluid" alt="mdh" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={nestle} className="img-fluid" alt="nestle" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={pg} className="img-fluid" alt="p&g" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={parle} className="img-fluid" alt="parle" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={patanjali} className="img-fluid" alt="patanjali" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={pepsi} className="img-fluid" alt="pepsi" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={philips} className="img-fluid" alt="philips" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={skybags} className="img-fluid" alt="skybags" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
