import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";

import { Swiper, SwiperSlide } from "swiper/react";
import Affordabilityimg from "../../assets/img/marketoverview/Affordability.jpg";
import CompetitionExommerce from "../../assets/img/marketoverview/Competition from E-commerce.jpg";
import LackModern from "../../assets/img/marketoverview/Lack of Modern Infrastructure.jpg";
import LimitedProduct from "../../assets/img/marketoverview/Limited Product Range and Inventory Management.jpg";
import MarketOverviewImg from "../../assets/img/marketoverview/MarketOverview.jpg";
import MarketPenetration from "../../assets/img/marketoverview/MarketPenetration.png";
import Profitability from "../../assets/img/marketoverview/Profitability.png";
import Scalability from "../../assets/img/marketoverview/Scalability.png";
import TechnologyGap from "../../assets/img/marketoverview/technologygap.jpg";
import IncreasedSales from "../../assets/img/why-us/Increased-Sales.png";
import Government from "../../assets/img/marketoverview/Government Initiatives and Support.png";
import Proximity from "../../assets/img/marketoverview/Proximity and Trust.png";
import Potential from "../../assets/img/marketoverview/Potential for Modernization.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
export default function MarketOverview() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing market-overview-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <img src={MarketOverviewImg} className="img-fluid h-100" alt="Market" />
            </div>
            <div className="col-lg-6">
              <div className="main-heading">
                <span>Market Overview</span>
                <h2>In-Depth Analysis of Market Trends and Opportunities</h2>
              </div>
              <p>
                India's retail market is one of the largest and fastest-growing
                in the world, with an estimated value of over $1 trillion.
                Within this vast market, Kirana stores—a traditional form of
                small, family-owned shops—play a crucial role. They account for
                nearly 85% of the retail market and serve as the backbone of
                Indian retail, especially in semi-urban and rural areas.
              </p>
              <div className="row mt-4">
                <div className="col-lg-6 mb-4 one mb-lg-0">
                  <div className="potential-card ">
                    <div className="icon">
                      <img
                        src={Affordabilityimg}
                        className="img-fluid"
                        alt="Affordability"
                      />
                    </div>
                    <h4>Affordability</h4>
                    <p>
                      These models are designed to cater to a wide range of
                      investors, from small-scale Kirana owners to larger retail
                      players.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 two mb-lg-0">
                  <div className="potential-card ">
                    <div className="icon">
                      <img
                        src={Profitability}
                        className="img-fluid"
                        alt="Profitability"
                      />
                    </div>
                    <h4>Profitability</h4>
                    <p>
                      The break-even period is estimated between 12-18 months,
                      ensuring a viable business model.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 three mb-lg-0">
                  <div className="potential-card ">
                    <div className="icon">
                      <img
                        src={Scalability}
                        className="img-fluid"
                        alt="Scalability"
                      />
                    </div>
                    <h4>Scalability</h4>
                    <p>
                      Franchisees have the flexibility to upgrade from Mini to
                      Standard or Premium franchises as their business grows.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 four mb-lg-0">
                  <div className="potential-card ">
                    <div className="icon">
                      <img
                        src={MarketPenetration}
                        className="img-fluid"
                        alt="Market Penetration"
                      />
                    </div>
                    <h4>Market Penetration</h4>
                    <p>
                      The investment options allow penetration into urban,
                      semi-urban, and rural markets, broadening the reach of
                      Superkirana.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="market-parallax section-spacing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="main-heading main-heading-center">
                <span>The Indian Retail Landscape</span>
                <h2>
                  Modernize and adapt with SuperKirana to stay competitive in a
                  changing market.
                </h2>
                {/* <p>
                  Despite the emergence of organized retail chains and the
                  growing popularity of online grocery platforms, Kirana stores
                  continue to thrive due to their deep-rooted presence in local
                  communities. These stores offer personalized services,
                  familiarity, and convenience that larger retailers often
                  cannot match. However, to stay competitive in a rapidly
                  evolving retail landscape, Kirana stores need to modernize and
                  adapt to changing consumer expectations.
                </p> */}
                <div className="d-flex justify-content-center">
                  <a href="tel:+911234567890" className="theme-btn">
                    Call Us Now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-spacing challenges-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Challenges for Kirana Stores</span>
                <h2>Exploring the Key Challenges and Difficulties for Kirana Stores</h2>
              </div>
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper pb-5 ">
                <SwiperSlide>
                  <div className="challenges-card">
                    <div className="icon">
                      <img src={CompetitionExommerce} className="img-fluid" alt="Competition from E-commerce" />
                    </div>
                    <h4>Competition from E-commerce</h4>
                    <p>
                      The ease of home delivery and the growing preference for
                      online shopping, especially among younger consumers, poses
                      a challenge to the traditional business model of Kirana
                      stores.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="challenges-card">
                    <div className="icon">
                      <img src={LackModern} className="img-fluid" alt="Lack of Modern Infrastructure" />
                    </div>
                    <h4>Lack of Modern Infrastructure</h4>
                    <p>
                      Many Kirana stores operate with outdated infrastructure,
                      including inadequate shelving, poor lighting, and
                      inefficient billing systems which limits the store’s
                      ability to retain customers.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="challenges-card">
                    <div className="icon">
                      <img src={LimitedProduct} className="img-fluid" alt="Limited Product Range and Inventory Management" />
                    </div>
                    <h4>Limited Product Range and Inventory Management</h4>
                    <p>
                      Kirana stores often struggle with inventory management due
                      to a lack of advanced systems. This can lead to stockouts,
                      overstocking, and missed sales opportunities.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="challenges-card">
                    <div className="icon">
                      <img src={TechnologyGap} className="img-fluid" alt="Technology Gap" />
                    </div>
                    <h4>Technology Gap</h4>
                    <p>
                      Most Kirana stores lag behind larger retailers by lacking
                      POS systems, digital payments, and an online presence,
                      leaving them at a disadvantage.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="section-spacing pt-0 opportunities-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Opportunities For Growth</span>
                <h2>Finding New Ways to Grow Your Local Retail Business</h2>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="opportunities-card">
                <img src={Proximity} className="img-fluid" alt="Proximity and Trust" />
                <h4>Proximity and Trust</h4>
                <p>
                  Kirana stores benefit from close proximity to customers and
                  strong personal relationships, offering a trust that larger
                  retailers can't match.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="opportunities-card">
                <img src={Potential} className="img-fluid" alt="Potential for Modernization" />
                <h4>Potential for Modernization</h4>
                <p>
                  With proper support, Kirana stores can modernize, enhance
                  customer experience, and compete with organized retail by
                  adopting technology, upgrading infrastructure, and expanding
                  product offerings.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="opportunities-card">
                <img src={Government} className="img-fluid" alt="Government Initiatives and Support" />
                <h4>Government Initiatives and Support</h4>
                <p>
                  The Indian government has launched initiatives to support
                  small retailers, including digitalization, financial aid, and
                  training, helping Kirana stores transition into modern retail.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
