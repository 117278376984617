import React, { useState } from "react";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import "./Calculator.css";

export default function HowWeWork() {
  const [squareFeet, setSquareFeet] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  const Franchise_fee = 177000; // Including GST fixed
  const Software_cost = 50000; // Fixed cost
  const cost_per_sqrft_product_filling = 1000; // fixed for interior and product filling (inventor)

  const handleCalculate = () => {
    if (squareFeet > 0) {
      setShowDetails(true);
    }
  };

  const calculateCosts = () => {
    const sqft = Number(squareFeet);
    const interiorCost = sqft * cost_per_sqrft_product_filling;
    const productFillingCost = sqft * cost_per_sqrft_product_filling;
    const totalCost =
      Franchise_fee + Software_cost + interiorCost + productFillingCost;

    return {
      interiorCost,
      productFillingCost,
      totalCost,
    };
  };

  const { interiorCost, productFillingCost, totalCost } = calculateCosts();

  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing how-we-work-page">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 transformation-process-section section-spacing-y pb-0">
              <div className="main-heading main-heading-center">
                <span>Budget Planning</span>
                <h2>Using Tools for Investment Analysis</h2>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="work-card">
                <div className="row">
                  <div className="col-12">
                    <p className="fw-bold fs-5">Enter Square Feet</p>

                    <input
                      className="py-2 px-2 my-2 w-100 calculator-input"
                      type="text"
                      value={squareFeet}
                      onChange={(e) => setSquareFeet(e.target.value)}
                      min="0"
                    />
                  </div>
                  <div className="col-12 d-flex">
                    <button
                      className="theme-btn py-3 mt-3 px-5"
                      onClick={handleCalculate}
                    >
                      Plan My Budget
                    </button>
                  </div>
                </div>

                {showDetails && (
                  <div style={{ marginTop: "20px", overflowX: "auto" }}>
                    <table className="calculator-main-box">
                      <thead>
                        <tr>
                          <th className="calculator-th"></th>
                          <th className="calculator-th">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="calculator-td">
                            <strong>Franchise Fee (18% GST included):</strong>
                          </td>
                          <td className="calculator-td text-end">
                            ₹{Franchise_fee.toLocaleString()}
                          </td>
                        </tr>
                        <tr>
                          <td className="calculator-td">
                            <strong>Single Software Fee:</strong>
                          </td>
                          <td className="calculator-td text-end">
                            ₹{Software_cost.toLocaleString()}
                          </td>
                        </tr>
                        <tr>
                          <td className="calculator-td">
                            <strong>Interior Cost:</strong>
                          </td>
                          <td className="calculator-td text-end">
                            ₹{interiorCost.toLocaleString()}
                          </td>
                        </tr>
                        <tr>
                          <td className="calculator-td">
                            <strong>Product Filling Cost:</strong>
                          </td>
                          <td className="calculator-td text-end">
                            ₹{productFillingCost.toLocaleString()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="calculator-approx-value">
                      Approx. Total Cost: ₹{totalCost.toLocaleString()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
