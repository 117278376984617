import { Link } from "react-router-dom";
import "./BlogSection.css";
import SuperKiranaLocalKirana from "../../../assets/img/blogs/superKiranaLocalKirana.jpg";
import SuperKiranaInitiative from "../../../assets/img/blogs/SuperKiranaInitiative.jpg";
import SuperKiranabusiness from "../../../assets/img/blogs/superKiranabusiness.jpg";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
export default function BlogSection() {
  return (
    <section className="blog-section section-spacing">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="main-heading main-heading-center">
              <span>Blogs</span>
              <h2>Explore Our Latest Posts</h2>
            </div>
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              loop={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Pagination]}
              className="mySwiper pb-5 pb-lg-0">
              <SwiperSlide>
                <div className="blog-card">
                  <div className="image">
                    <Link to="/revolutionizing-local-kirana-stores-across-india">
                      <img src={SuperKiranaLocalKirana} className="img-fluid" alt="SuperKirana: Revolutionizing Local Kirana Stores Across
                        India" />
                    </Link>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/revolutionizing-local-kirana-stores-across-india">
                        SuperKirana: Revolutionizing Local Kirana Stores Across
                        India
                      </Link>
                    </h5>
                    <p>
                      In today's fast-paced environment, ease and accessibility
                      are major motivators for customer behavior. This is where
                      SuperKirana comes in, transforming traditional
                      neighborhood Kirana businesses into modern, digitally
                      connected retail hubs that meet the demands of the local
                      community.
                    </p>
                    <div className="btn-box">
                      <p>03 Sep, 2024</p>
                      <Link
                        to="/revolutionizing-local-kirana-stores-across-india"
                        className="theme-btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="blog-card">
                  <div className="image">
                    <Link to="/a-revolutionary-initiative-by-mathills-pvt-ltd">
                      <img src={SuperKiranaInitiative} className="img-fluid" alt="A Revolutionary Initiative by Mathills Pvt.
                        Ltd." />
                    </Link>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/a-revolutionary-initiative-by-mathills-pvt-ltd">
                        SuperKirana: A Revolutionary Initiative by Mathills Pvt.
                        Ltd.
                      </Link>
                    </h5>
                    <p>
                      Mathills Pvt. Ltd. is leading the way in modifying small
                      Kirana stores across India with their creating concept,
                      SuperKirana. SuperKirana, a Mathills division, aspires to
                      modernize and elevate traditional grocery stores into
                      streamlined, efficient retail hubs that provide
                      high-quality, low-cost products to local communities.
                    </p>
                    <div className="btn-box">
                      <p>03 Sep, 2024</p>
                      <Link
                        to="/a-revolutionary-initiative-by-mathills-pvt-ltd"
                        className="theme-btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="blog-card">
                  <div className="image">
                    <Link to="/how-superKirana-can-help-you-grow-your-kirana-business">
                      <img src={SuperKiranabusiness} className="img-fluid" alt="" />
                    </Link>
                  </div>
                  <div className="content">
                    <h5>
                      <Link to="/how-superKirana-can-help-you-grow-your-kirana-business">
                        How SuperKirana Can Help You Grow Your Kirana Business
                      </Link>
                    </h5>
                    <p>
                      Managing a successful Kirana store in today's competitive
                      industry can be difficult. With the development of modern
                      retail chains and e-commerce behemoths, traditional Kirana
                      stores frequently struggle to compete.
                    </p>
                    <div className="btn-box">
                      <p>03 Sep, 2024</p>
                      <Link
                        to="/how-superKirana-can-help-you-grow-your-kirana-business"
                        className="theme-btn">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
