import { MdOutlineWarehouse } from "react-icons/md";
import SubmitApplication from "../../../assets/img/application/submit.png";
import InitialReview from "../../../assets/img/application/Initial-Review.png";
import Interview from "../../../assets/img/application/interview.png";
import SiteVerification from "../../../assets/img/application/Site-Verification.png";
import Approval from "../../../assets/img/application/approved.png";


import Agreement from "../../../assets/img/application/AGREEMENT SIGN.png";
import Areacode from "../../../assets/img/application/AREA CODE ACTIVATION.png";
import Clientmeeting from "../../../assets/img/application/CLIENT MEETING.png";
import Fixappointment from "../../../assets/img/application/FIX APPOINTMENT.png";
import Franchiseverification from "../../../assets/img/application/FRANCHISE VERIFICATION.png";
import Handlingsuperkirana from "../../../assets/img/application/HANDLING super kirana KIT.png";
import "./ApplicationSection.css";
export default function ApplicationSection() {
  return (
    <section className="application-section pb-0 section-spacing">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="main-heading main-heading-center">
              <span>Application Process</span>
              <h2>
                Here’s how you can become a part of our successful community.
              </h2>
            </div>
            <div className="application-box section-spacing-x">
              <div className="application-card">
                <span>
                  <img
                    src={Agreement}
                    className="img-fluid"
                    alt="Submit Application"
                  />
                </span>
                <h4> Agreement Sign</h4>
                {/* <p>Fill out the Franchise Application form on the website</p> */}
              </div>
              <div className="application-card">
                <span>
                  <img
                    src={Areacode}
                    className="img-fluid"
                    alt="Initial Review"
                  />
                </span>
                <h4>Area Code Activation</h4>
                {/* <p>Our team will review your Franchise application form</p> */}
              </div>
              <div className="application-card">
                <span>
                  <img src={Clientmeeting} className="img-fluid" alt="Interview" />
                </span>
                <h4> Client Meeting</h4>
                {/* <p>Our franchise evaluation team will interview you</p> */}
              </div>
              <div className="application-card">
                <span>
                  <img
                    src={Fixappointment}
                    className="img-fluid"
                    alt="Site Verification"
                  />
                </span>
                <h4>Fix Appointment</h4>
                {/* <p>Appropriate site location & verification will be done</p> */}
              </div>
              <div className="application-card">
                <span>
                  <img src={Franchiseverification} className="img-fluid" alt="Approval" />
                </span>
                <h4>Franchise Verification</h4>
                {/* <p>Receive approval and start the Superkirana journey</p> */}
              </div>
              <div className="application-card">
                <span>
                  <img src={Handlingsuperkirana} className="img-fluid" alt="Approval" />
                </span>
                <h4>Handling Super Kirana Kit</h4>
                {/* <p>Receive approval and start the Superkirana journey</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
