import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import IncreasedSales from "../../assets/img/why-us/Increased-Sales.png";
import Accessibility from "../../assets/img/siteselection/Accessibility.png";
import Competition from "../../assets/img/siteselection/Competition.png";
import CostConsideration from "../../assets/img/siteselection/Cost Considerations.png";
import Demographics from "../../assets/img/siteselection/Demographics.png";
import FinalSiteSelection from "../../assets/img/siteselection/Final Site Selection.png";
import LocalEconomy from "../../assets/img/siteselection/Local Economy and Development.png";
import SiteCharacteristics from "../../assets/img/siteselection/Site Characteristics.png";
import TrafficandVisibility from "../../assets/img/siteselection/Traffic and Visibility.png";

export default function SiteSelection() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing site-selection-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center mb-4">
                <span>Site Selection</span>
                <h2>Criteria for Optimal Store Location</h2>
                <p>
                  Choosing the right location is crucial to the success of your
                  Superkirana franchise. This document outlines the key factors
                  and criteria that should be considered when selecting a site
                  for your store. Please review these criteria carefully and
                  work closely with the Superkirana support team to ensure the
                  best possible site selection.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="site-card">
                <img src={Demographics} className="img-fluid" alt="Demographics" />

                <h4>Demographics</h4>
                <p>
                  <b> Population Density:</b>
                  Pick a location with lots of people.
                </p>
                <p>
                  <b>Household Income:</b>
                  Ensure local income matches SuperKirana’s products.
                </p>
                <p>
                  <b>Age Distribution:</b>
                  Align with the local age groups.
                </p>
                <p>
                  <b>Cultural Considerations:</b>
                  Match products to local cultural preferences.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="site-card">
                <img src={TrafficandVisibility} className="img-fluid" alt="Traffic and Visibility" />

                <h4>Traffic and Visibility</h4>
                <p>
                  <b> Foot Traffic:</b>
                  Choose a spot with lots of people passing by.
                </p>
                <p>
                  <b>Vehicle Traffic:</b>
                  Pick a location with heavy vehicle traffic for better
                  visibility.
                </p>
                <p>
                  <b>Visibility:</b>
                  Ensure the store is visible from main roads and well-lit at
                  night.
                </p>
                <p>
                  <b>Signage:</b>
                  Make sure you can place clear, prominent signs.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="site-card">
                <img src={Accessibility} className="img-fluid" alt="Accessibility" />

                <h4>Accessibility</h4>
                <p>
                  <b>Parking:</b>
                  Ensure there’s ample parking on-site or nearby.
                </p>
                <p>
                  <b>Public Transportation:</b>
                  Choose a location accessible by public transport.
                </p>
                <p>
                  <b>Entrance and Exit:</b>
                  Provide easy access for both vehicles and pedestrians.
                </p>
                <p>
                  <b>Handicap Accessibility:</b>
                  Comply with local regulations for handicap access.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="site-card">
                <img src={Competition} className="img-fluid" alt="Competition" />

                <h4>Competition</h4>
                <p>
                  <b>Proximity to Competitors:</b>
                  Check nearby competitors and their impact on your store.
                </p>
                <p>
                  <b>Market Saturation:</b>
                  Avoid areas with too many similar stores.
                </p>
                <p>
                  <b>Competitive Advantage:</b>
                  Highlight what sets your store apart from local competitors.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="site-card">
                <img src={LocalEconomy} className="img-fluid" alt="Local Economy and Development" />

                <h4>Local Economy and Development</h4>
                <p>
                  <b>Economic Stability:</b>
                  Choose a location with a stable economy and growth potential.
                </p>
                <p>
                  <b>Development Plans:</b>
                  Check for upcoming developments that might affect the area.
                </p>
                <p>
                  <b>Zoning Regulations:</b>
                  Ensure the location meets local zoning laws for retail.
                </p>
                <p>
                  <b>Nearby Businesses:</b>
                  Look for complementary businesses that can attract more
                  customers.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="site-card">
                <img src={SiteCharacteristics} className="img-fluid" alt="Site Characteristics" />

                <h4>Site Characteristics</h4>
                <p>
                  <b>Store Size:</b>
                  The site should accommodate the required store size, including
                  backroom and storage areas.
                </p>
                <p>
                  <b>Lease Terms:</b>
                  Favorable lease terms and conditions should be negotiated,
                  including renewal options.
                </p>
                <p>
                  <b>Utilities and Infrastructure:</b>
                  Ensure that the site has the necessary utilities (water,
                  electricity, internet) and infrastructure in place.
                </p>
                <p>
                  <b>Security:</b>
                  The location should be in a safe area with appropriate
                  security measures in place.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="site-card">
                <img src={CostConsideration} className="img-fluid" alt="Cost Considerations" />

                <h4>Cost Considerations</h4>
                <p>
                  <b>Rent and Lease Costs:</b>
                  Assess if the rent fits your budget and revenue projections.
                </p>
                <p>
                  <b>Build-Out and Renovation Costs:</b>
                  Factor in costs for building out or renovating the space.
                </p>
                <p>
                  <b>Operating Costs:</b>
                  Evaluate ongoing costs like utilities, taxes, and maintenance.
                </p>
                <p>
                  <b>Return on Investment:</b>
                  Calculate ROI based on location costs and revenue projections.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="site-card">
                <img src={FinalSiteSelection} className="img-fluid" alt="Final Site Selection" />

                <h4>Final Site Selection</h4>
                <p>
                  The final site selection should be based on careful
                  consideration of all criteria. Work closely with the
                  SuperKirana support team, complete a site visit, market
                  analysis, and financial projections before deciding.
                </p>
                <p>
                  Choosing the right location is crucial for your store’s
                  success. Following these criteria will help ensure a site that
                  supports long-term success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
