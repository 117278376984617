import amul from "../assets/img/brands/amul.png";
import bajaj from "../assets/img/brands/bajaj.png";
import bikaji from "../assets/img/brands/bikaji.png";
import britannia from "../assets/img/brands/britannia.png";
import cadbury from "../assets/img/brands/cadbury.png";
import Catch from "../assets/img/brands/catch.png";
import cocacola from "../assets/img/brands/coca-cola.png";
import colgate from "../assets/img/brands/colgate.png";
import dettol from "../assets/img/brands/dettol.png";
import fortune from "../assets/img/brands/fortune.png";
import haldiram from "../assets/img/brands/haldiram's.png";
import itc from "../assets/img/brands/itc.png";
import johnson from "../assets/img/brands/johnson.png";
import loreal from "../assets/img/brands/loreal.png";
import mdh from "../assets/img/brands/mdh.png";
import nestle from "../assets/img/brands/nestle.png";
import pg from "../assets/img/brands/p&g.png";
import parle from "../assets/img/brands/parle.png";
import patanjali from "../assets/img/brands/patanjali.png";
import pepsi from "../assets/img/brands/pepsi.png";
import philips from "../assets/img/brands/philips.png";
import skybags from "../assets/img/brands/skybags.png";
import InnerBanner from "../Components/InnerBanner/InnerBanner";
import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
export default function Brands() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing brands-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Brands</span>
                <h2>Featured Brand Partners</h2>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={amul} className="img-fluid" alt="amul" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={bajaj} className="img-fluid" alt="bajaj" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={bikaji} className="img-fluid" alt="bikaji" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={britannia} className="img-fluid" alt="britannia" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={cadbury} className="img-fluid" alt="cadbury" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={Catch} className="img-fluid" alt="catch" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={cocacola} className="img-fluid" alt="cocacola" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={colgate} className="img-fluid" alt="colgate" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={dettol} className="img-fluid" alt="dettol" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={fortune} className="img-fluid" alt="fortune" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={haldiram} className="img-fluid" alt="haldiram" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={itc} className="img-fluid" alt="itc" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img
                src={johnson}
                className="img-fluid"
                alt="johnson & johnson"
              />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={loreal} className="img-fluid" alt="loreal" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={mdh} className="img-fluid" alt="mdh" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={nestle} className="img-fluid" alt="nestle" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={pg} className="img-fluid" alt="p&g" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={parle} className="img-fluid" alt="parle" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={patanjali} className="img-fluid" alt="patanjali" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={pepsi} className="img-fluid" alt="pepsi" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={philips} className="img-fluid" alt="philips" />
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <img src={skybags} className="img-fluid" alt="skybags" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
