import { TbReceiptRupee } from "react-icons/tb";
import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../Components/InnerBanner/InnerBanner";
import LowCost from "../assets/img/why-us/Low-Cost-Entry.png";
import FlexibleFranchise from "../assets/img/why-us/Flexible-Franchise-Options.png";
import ComprehensiveSupport from "../assets/img/why-us/Comprehensive-Support.png";
import NoRisk from "../assets/img/why-us/No-Risk-Exploration.png";

import IncreasedSales from "../assets/img/why-us/Increased-Sales.png";
import ImprovedProfit from "../assets/img/why-us/Improved-Profit.png";
import EnhancedCustomer from "../assets/img/why-us/Enhanced-Customer-Retention.png";
import LowerOperational from "../assets/img/why-us/Lower-Operational-Costs.png";
import RightBanner from "../assets/img/why-us/Maximizing-banner.jpg";
export default function WhyUs() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing whyus-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center mb-4">
                <span>Why SuperKirana?</span>
                <h2>Capitalize on a Profitable Business Opportunity with Flexible
                Investment Options</h2>
                <p>
                  SuperKirana offers a unique solution to the challenges faced
                  by Kirana stores. By partnering with SuperKirana, store owners
                  can modernize their operations, improve customer experience,
                  and increase profitability, all while maintaining the essence
                  of their traditional business model.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
              <div className="why-card">
                <img src={LowCost} className="img-fluid" alt="Low-Cost Entry" />
                <h4>Low-Cost Entry</h4>
                <p>
                  Start your store with minimal investment and flexible payment
                  options, designed to reduce your financial burden.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
              <div className="why-card">
                <img
                  src={FlexibleFranchise}
                  className="img-fluid"
                  alt="Flexible Franchise Options"
                />
                <h4>Flexible Franchise Options</h4>
                <p>
                  Whether you’re just starting out or looking to upgrade an
                  existing store, Superkirana has a plan that fits your needs.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
              <div className="why-card">
                <img
                  src={ComprehensiveSupport}
                  className="img-fluid"
                  alt="Comprehensive Support"
                />
                <h4>Comprehensive Support</h4>
                <p>
                  From initial survey to grand launch, Superkirana provides
                  end-to-end support, ensuring your success every step of the
                  way.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
              <div className="why-card">
                <img
                  src={NoRisk}
                  className="img-fluid"
                  alt="No-Risk Exploration"
                />
                <h4>No-Risk Exploration</h4>
                <p>
                  With the option to opt-in or opt-out after onboarding,
                  Superkirana gives you the freedom to assess your eligibility
                  and choose the plan that works best for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-spacing pt-3 whyus-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="main-heading">
                <span>Achieve Maximum Growth</span>
                <h2>Maximizing Your Store’s Potential with Superkirana</h2>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-4 one mb-lg-0">
                  <div className="potential-card ">
                    <div className="icon">
                      <img
                        src={IncreasedSales}
                        className="img-fluid"
                        alt="Increased Sales by 20%-35%"
                      />
                    </div>
                    <h4>Increased Sales by 20%-35%</h4>
                    <p>
                      Storeowners in the Superkirana network see an average
                      sales increase of 20%-35% within the first 6 months.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 two mb-lg-0">
                  <div className="potential-card ">
                    <div className="icon">
                      <img
                        src={ImprovedProfit}
                        className="img-fluid"
                        alt="Improved Profit Margins by 10%-15%"
                      />
                    </div>
                    <h4>Improved Profit Margins by 10%-15%</h4>
                    <p>
                      With better procurement and supply chain management,
                      profit margins rise by 10%-15%.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 three mb-lg-0">
                  <div className="potential-card ">
                    <div className="icon">
                      <img
                        src={EnhancedCustomer}
                        className="img-fluid"
                        alt="Enhanced Customer Retention"
                      />
                    </div>
                    <h4>Enhanced Customer Retention</h4>
                    <p>
                      Loyalty programs and better customer service increase
                      repeat business by 25%.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 four mb-lg-0">
                  <div className="potential-card ">
                    <div className="icon">
                      <img
                        src={LowerOperational}
                        className="img-fluid"
                        alt="Lower Operational Costs"
                      />
                    </div>
                    <h4>Lower Operational Costs</h4>
                    <p>
                      Through the use of advanced POS systems and inventory
                      management, operational costs can be reduced by 10%.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={RightBanner}
                className="img-fluid border-radius"
                alt="Maximizing Your Store’s Potential with Superkirana"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
