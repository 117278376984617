import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import IncreasedSales from "../../assets/img/why-us/Increased-Sales.png";
import Step1 from "../../assets/img/franchisemodels/Step 1 Initial Inquiry and Token Payment.jpg";
import Step2 from "../../assets/img/franchisemodels/Step 2 Conducting the Survey.png";
import Step3 from "../../assets/img/franchisemodels/Step 3 Onboarding and Credit Facility.jpg";
import Step4 from "../../assets/img/franchisemodels/Step 4 Decision and Interior Setup.png";
import Step5 from "../../assets/img/franchisemodels/Step 5 Final Onboarding and Store Launch.png";
export default function FranchiseModels() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing franchise-models-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Start Your Store with Us Now at Zero Cost</span>
                <h2>Join the Superkirana Revolution – Your Store, Your Way!</h2>
                <p>
                  Superkirana is committed to empowering local Kirana store
                  owners and aspiring entrepreneurs by offering flexible,
                  tailored franchise plans that cater to a variety of needs and
                  budgets. With our streamlined onboarding process and
                  customizable options, opening your own Superkirana store has
                  never been easier. Here’s how you can get started:
                </p>
              </div>
              <div className="main-heading main-heading-center mb-4 mt-4">
                <span>Choose Your Franchise Plan</span>
                <h2 className="mb-4 text-capitalize">
                  Superkirana offers three distinct franchise models
                </h2>
              </div>
            </div>
           
            <div className="col-lg-6 mb-4">
              <div className="plan-card">
                <div className="main-heading">
                  <span className="fs-4">1. Mini Franchise</span>
                  <p className="fs-5 fw-bold">Initial Investment: ₹5-6 Lakhs</p>
                </div>
                <ul>
                  <li>
                    <p>
                      <b>Ideal For:</b> Smaller stores or new entrants in the
                      retail market.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Initial Investment:</b> ₹5-6 Lakhs
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Token Amount:</b>
                      ₹20,000
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Survey and Onboarding</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      Pay the token amount to initiate a detailed survey of your
                      store location.
                    </p>
                  </li>
                  <li>
                    <p>
                      After a favorable survey report, pay the remaining
                      ₹30,000.
                    </p>
                  </li>
                  <li>
                    <p>
                      Onboarding starts with the survey report, including all
                      legal and paperwork.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Flexibility:</b>
                      Post-onboarding, you can upgrade or switch your franchise
                      model based on your budget and join the SuperKirana
                      community to enjoy its benefits.
                    </p>
                  </li>
                </ul>
                <b>Breakdown</b>
                <p>Branding and Basic Store Setup: ₹2-2.5 Lakhs</p>
                <p>Initial Inventory: ₹2.5-3 Lakhs</p>
                <p>Technology Setup: ₹0.5-1 Lakh</p>
                <p>Marketing: ₹0.5 Lakh</p>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="plan-card">
                <div className="main-heading">
                  <span className="fs-4">
                    2. Standard Franchise (Store Upgrade Model)
                  </span>
                  <p className="fs-5 fw-bold">
                    Initial Investment: ₹10-12 Lakhs
                  </p>
                </div>
                <ul>
                  <li>
                    <p>
                      <b>Ideal For:</b> Existing Kirana store owners looking to
                      upgrade.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Initial Investment:</b> ₹10-12 Lakhs
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Token Amount:</b>
                      ₹20,000
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Survey and Onboarding</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      Pay the token amount to start a detailed survey of your
                      store.
                    </p>
                  </li>
                  <li>
                    <p>
                      After the survey, pay the remaining ₹30,000 to proceed
                      with onboarding under a credit facility scheme.
                    </p>
                  </li>
                  <li>
                    <p>
                      Interior renovation begins once the credit limit is
                      approved.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Flexibility:</b>
                      After onboarding, you can upgrade or switch your franchise
                      model based on your budget and join the SuperKirana
                      community to enjoy its benefits.
                    </p>
                  </li>
                </ul>
                <b>Breakdown</b>
                <p>Branding and Store Renovation: ₹3-4 Lakhs</p>
                <p>Initial Inventory: ₹4-5 Lakhs</p>
                <p>Technology Setup: ₹1.5-2 Lakhs</p>
                <p>Marketing and Launch: ₹1-1.5 Lakhs</p>
              </div>
            </div>
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="plan-card">
                <div className="main-heading">
                  <span className="fs-4">3. Premium Franchise</span>
                  <p className="fs-5 fw-bold">
                    Initial Investment: ₹18-20 Lakhs
                  </p>
                </div>
                <ul>
                  <li>
                    <p>
                      <b>Ideal For:</b> Larger stores or flagship SuperKirana
                      outlets.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Initial Investment:</b> ₹18-20 Lakhs
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Token Amount:</b>
                      ₹20,000
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Survey and Onboarding</b>
                    </p>
                  </li>
                  <li>
                    <p>
                      Start with the Mini or Standard process and upgrade to
                      Premium based on your survey report.
                    </p>
                  </li>
                  <li>
                    <p>
                      Premium includes a complete store transformation, advanced
                      training, and strategic marketing support.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Flexibility:</b>
                      Post-onboarding, you can upgrade or switch your franchise
                      model to fit your budget and join the SuperKirana
                      community.
                    </p>
                  </li>
                </ul>
                <b>Breakdown</b>
                <p>Premium Branding and Store Transformation: ₹6-7 Lakhs</p>
                <p>Extensive Inventory: ₹6-7 Lakhs</p>
                <p>Advanced Technology Setup: ₹3-4 Lakhs</p>
                <p>Comprehensive Marketing and Grand Launch: ₹3 Lakhs</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-spacing pt-0 enroll-process-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Enroll and Start the Process</span>
                <h2>Step-by-Step Process to Join Superkirana</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="enroll-card">
                <img src={Step1} className="img-fluid" alt="Initial Inquiry and Token Payment" />
                <h4>Step 1: Initial Inquiry and Token Payment</h4>
                <p>
                  <b>Survey Schedule:</b>
                  Contact us or visit our website to express interest.
                </p>
                <p>
                  <b>Token Payment:</b>
                  Pay ₹20,000 online to secure your spot in the onboarding
                  process.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="enroll-card">
                <img src={Step2} className="img-fluid" alt="Conducting the Survey" />
                <h4>Step 2: Conducting the Survey</h4>
                <p>
                  <b>Schedule:</b>
                  We’ll arrange a survey after your token payment.
                </p>
                <p>
                  <b>Report:</b>
                  Our team will evaluate the location, including demographics
                  and competition.
                </p>
                <p>
                  <b>Remaining Payment:</b>
                  After the survey, pay ₹30,000 to proceed with onboarding.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="enroll-card">
                <img src={Step3} className="img-fluid" alt="Onboarding and Credit Facility" />
                <h4>Step 3: Onboarding and Credit Facility</h4>
                <p>
                  <b>Onboarding:</b>
                  After payment, we begin the onboarding process, including all
                  necessary documentation.
                </p>
                <p>
                  <b>Credit Facility:</b>
                  Get approved for a flexible credit facility to start your
                  business with minimal upfront costs and manageable repayments.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="enroll-card">
                <img src={Step4} className="img-fluid" alt="Decision and Interior Setup" />
                <h4>Step 4: Decision and Interior Setup</h4>
                <p>
                  <b>Choose Your Plan:</b>
                  Pick Mini, Standard, or Premium based on your budget.
                </p>
                <p>
                  <b>Interior Setup:</b>
                  For Standard or Premium, we’ll start renovations, with
                  payments matching setup costs.
                </p>
                <p>
                  <b>Flexibility:</b>
                  Upgrade or switch your model post-onboarding to fit your
                  budget and join the SuperKirana community.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="enroll-card">
                <img src={Step5} className="img-fluid" alt="Final Onboarding and Store Launch" />
                <h4>Step 5: Final Onboarding and Store Launch</h4>
                <p>
                  <b>Complete Onboarding:</b>
                  Finalize formalities, sign the agreement, and complete
                  remaining payments.
                </p>
                <p>
                  <b>Training and Support:</b>
                  Get training on store management, customer service, and our
                  operational tools.
                </p>
                <p>
                  <b>Grand Launch:</b>
                  Your store is ready to launch with our marketing support to
                  generate local buzz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
