import { Input } from "antd";
import InnerBanner from "../Components/InnerBanner/InnerBanner";
import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import TextArea from "antd/es/input/TextArea";
import { Button } from "react-bootstrap";
import Hul from "../assets/img/partnership/Supplier/hul.jpeg";
import Nestle from "../assets/img/partnership/Supplier/nestle-logo.jpg";
import Marico from "../assets/img/partnership/Supplier/Marico-Logo.svg";
import Itc from "../assets/img/partnership/Supplier/ITC-Limited-logo.jpg";
import Dabur from "../assets/img/partnership/Supplier/Dabur-Logo.png";

import DHL from "../assets/img/partnership/Partners/DHL_Supply_Chain_logo.png";
import Delhivery from "../assets/img/partnership/Partners/Delhivery.webp";
import Gati from "../assets/img/partnership/Partners/gati.png";
import BlueDart from "../assets/img/partnership/Partners/Blue_Dart.png";
import Rivigo from "../assets/img/partnership/Partners/Rivigo.jpg";

import Bajaj from "../assets/img/partnership/Credit-Facility/bajaj-finance.jpg";
import ePayLater from "../assets/img/partnership/Credit-Facility/ePayLater.png";
import Capital from "../assets/img/partnership/Credit-Facility/capital-float-logo.png";
import Lendingkart from "../assets/img/partnership/Credit-Facility/Lendingkart-logo.png";

import Zoho from "../assets/img/partnership/Technology/zoho.png";
import Razorpay from "../assets/img/partnership/Technology/Razorpay_logo.svg";
import PartnerShip from "../assets/img/partnership/Partners/partnership image (1).jpg";
import ContactForm from "../Pages/ContactForm/ContactForm";
export default function Partnerships() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing partnerships-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 section-spacing-y pt-0">
              <div className="main-heading mb-4">
                <span>Our Esteemed Supplier and Manufacturer Partners</span>
              </div>
              <div className="supplier-box">
                <div className="supplier-card">
                  <img
                    src={Hul}
                    className="img-fluid"
                    alt="Hindustan Unilever (HUL) Logo"
                  />
                </div>
                <div className="supplier-card">
                  <img
                    src={Nestle}
                    className="img-fluid"
                    alt="Nestlé India Logo"
                  />
                </div>
                <div className="supplier-card">
                  <img src={Marico} className="img-fluid" alt="Marico Logo" />
                </div>
                <div className="supplier-card">
                  <img src={Itc} className="img-fluid" alt="ITC Ltd. Logo" />
                </div>
                <div className="supplier-card">
                  <img
                    src={Dabur}
                    className="img-fluid"
                    alt="Dabur India Ltd. Logo"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 section-spacing-y pt-0">
              <div className="main-heading mb-4">
                <span>
                  Forward Tie-up Partners (Logistics and Distribution)
                </span>
              </div>
              <div className="supplier-box">
                <div className="supplier-card">
                  <img
                    src={DHL}
                    className="img-fluid"
                    alt="DHL Supply Chain India Logo"
                  />
                </div>
                <div className="supplier-card">
                  <img
                    src={Delhivery}
                    className="img-fluid"
                    alt="Delhivery Logo"
                  />
                </div>
                <div className="supplier-card">
                  <img
                    src={Gati}
                    className="img-fluid"
                    alt="Gati Logistics Logo"
                  />
                </div>
                <div className="supplier-card">
                  <img
                    src={BlueDart}
                    className="img-fluid"
                    alt="Blue Dart Logo"
                  />
                </div>
                <div className="supplier-card">
                  <img src={Rivigo} className="img-fluid" alt="Rivigo Logo" />
                </div>
              </div>
            </div>
            <div className="col-12 section-spacing-y pt-0">
              <div className="main-heading mb-4">
                <span>Credit Facility Partners</span>
              </div>
              <div className="supplier-box justify-content-start gap-4">
                <div className="supplier-card">
                  <img
                    src={Bajaj}
                    className="img-fluid"
                    alt="Bajaj Finserv Logo"
                  />
                </div>
                <div className="supplier-card">
                  <img
                    src={ePayLater}
                    className="img-fluid"
                    alt="ePayLater Logo"
                  />
                </div>
                <div className="supplier-card">
                  <img
                    src={Capital}
                    className="img-fluid"
                    alt="Capital Float Logo"
                  />
                </div>
                <div className="supplier-card">
                  <img
                    src={Lendingkart}
                    className="img-fluid"
                    alt="Lendingkart Logo"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 section-spacing-y pt-0">
              <div className="main-heading mb-4">
                <span>Technology and Payment Partners</span>
              </div>
              <div className="supplier-box justify-content-start gap-4">
                <div className="supplier-card">
                  <img
                    src={Zoho}
                    className="img-fluid"
                    alt="Zoho Corporation Logo"
                  />
                </div>
                <div className="supplier-card">
                  <img
                    src={Razorpay}
                    className="img-fluid"
                    alt="Razorpay Logo"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={PartnerShip} className="img-fluid" alt="PartnerShip" />
            </div>
            <div className="col-lg-6">
              <div className="main-heading">
                <span>Partnerships</span>
                <h2>Join the Super Kirana Community:</h2>
                <p className="mb-3">
                  Become a part of our supportive network. Collaborate with us
                  to enhance your business operations, reach a broader customer
                  base, and benefit from our extensive support and innovative
                  solutions.
                </p>
              </div>
              <div className="enquiry-form row">
                <ContactForm/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
