import NoImg from "../../../assets/img/no-image.jpg";
import Mission from "../../../assets/img/the-brand/mission.png";
import Vision from "../../../assets/img/the-brand/vision.png";
import TopLeft from "../../../assets/img/the-brand/top-left.jpg";
import TopRight from "../../../assets/img/the-brand/top-right.jpg";
import Bottom from "../../../assets/img/the-brand/bottom.jpg";
import "./AboutUs.css";
export default function AboutUs() {
  return (
    <section className="section-spacing about-us">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="d-flex justify-content-between">
              <img src={TopLeft} alt="" className="img-fluid" />
              <img src={TopRight} alt="" className="img-fluid" />
            </div>
            <img src={Bottom} alt="" className="img-fluid w-100" />
          </div>
          <div className="col-lg-6">
            <div className="main-heading">
              <span>Aapka Vyapar, Hamari Jimmedaari</span>
              <h2>Turning Your Kirana Store into a Super Retail Hub</h2>
            </div>
            <p className=" mb-2">
              SuperKirana is an innovative platform designed to elevate the
              traditional Kirana store into a modern retail destination. It
              integrates state-of-the-art technology, efficient store layouts,
              and contemporary branding to provide customers with a shopping
              experience that rivals larger retail chains, while preserving the
              unique charm and community focus of local Kirana stores.
            </p>
            <p className="mb-2">
              The core idea behind SuperKirana is to empower small and
              medium-sized store owners by providing them with the tools and
              support they need to succeed in a competitive market. By joining
              the SuperKirana network, store owners can upgrade their stores
              with minimal disruption, access a broader range of products, and
              benefit from centralized marketing and operational support.
            </p>
            <p className="mb-2">
              Superkirana is modernizing the traditional Kirana stores by
              providing access to better infrastructure, technology, branding,
              and ongoing business development. We help you compete with large
              retailers while maintaining your local customer base.
            </p>
            <div className="mv-card">
              <img src={Mission} className="img-fluid" alt="" />
              <div>
                <h4>Mission</h4>
                <p>
                  To empower grocery retailers with a community platform that
                  offers quality products, financial solutions, and advanced
                  management tools, driving collective success and growth
                </p>
              </div>
            </div>
            <div className="mv-card">
              <img src={Vision} className="img-fluid" alt="" />
              <div>
                <h4>Vision</h4>
                <p>
                  To transform grocery retail through a collaborative ecosystem,
                  enhancing the retail experience for communities across India.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
