import React, { useState } from "react";
import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../Components/InnerBanner/InnerBanner";

// Array of image
const imageData = {
  gallery: [
    { src: require('../assets/img/gallery/gallery1.jpeg'), alt: 'Gallery 1' },
    { src: require('../assets/img/gallery/gallery2.jpeg'), alt: 'Gallery 2' },
    { src: require('../assets/img/gallery/gallery3.jpeg'), alt: 'Gallery 3' },
    { src: require('../assets/img/gallery/gallery4.jpeg'), alt: 'Gallery 4' },
    { src: require('../assets/img/gallery/gallery5.jpeg'), alt: 'Gallery 5' },
    { src: require('../assets/img/gallery/gallery6.jpeg'), alt: 'Gallery 6' },
    { src: require('../assets/img/gallery/gallery7.jpeg'), alt: 'Gallery 7' },
    { src: require('../assets/img/gallery/gallery8.jpeg'), alt: 'Gallery 8' },
    { src: require('../assets/img/gallery/gallery9.jpeg'), alt: 'Gallery 9' },
    { src: require('../assets/img/gallery/gallery10.jpeg'), alt: 'Gallery 10' },
    { src: require('../assets/img/gallery/gallery11.jpeg'), alt: 'Gallery 11' },
    { src: require('../assets/img/gallery/gallery12.jpeg'), alt: 'Gallery 12' },
    { src: require('../assets/img/gallery/gallery13.jpeg'), alt: 'Gallery 13' },
    { src: require('../assets/img/gallery/gallery14.jpeg'), alt: 'Gallery 14' }
  ]
};
export default function Gallery() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing gallery-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Glimpses of SuperKirana</span>
                <h2> Experience Our Stores Up Close</h2>
              </div>
            </div>

            {imageData.gallery.map((image, index) => (
              <div key={index} className="col-md-6 col-lg-4 mb-4">
                <img src={image.src} className="img-fluid" alt={image.alt} />
              </div>
            ))}

          </div>
        </div>
      </section>
    </>
  );
}
