import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import "./BottomFixedLinks.css";
import GoToTop from "./GoToTop";
import { useEffect, useState } from "react";
export default function BottomFixedLinks() {
  const [atBottom, setAtBottom] = useState(false);

  const handleScroll = () => {
    const isBottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight;
    setAtBottom(isBottom);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`bottom-fixed-links ${atBottom ? "hide-btn" : ""}`}>
      <ul>
        <li>
          <span className="theme-btn cursor-pointer">
            <a href="/contact-us" className="bottom-apply-franchinse p-0 m-0">
              Apply for a Franchise
            </a>
          </span>
        </li>
        <li>
          <a href="tel:+919205781326">
            <IoIosCall />
          </a>
        </li>
        <li>
          <a href="https://wa.me/+919205781326" className="whatsapp-icon">
            <FaWhatsapp />
          </a>
        </li>
        <li>
          <GoToTop />
        </li>
      </ul>
    </div>
  );
}
