import { IoIosCall } from "react-icons/io";
import InnerBanner from "../Components/InnerBanner/InnerBanner";
import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Button } from "react-bootstrap";
import { IoMail } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import ContactForm from "./ContactForm/ContactForm";
export default function ContactUs() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing contact-us-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="main-heading">
                <span>Contact Form</span>
                <h2>Send Us a Message</h2>
                <p className="mb-4">
                  We’d love to hear from you! Whether you have questions,
                  feedback, or need assistance, feel free to reach out. Simply
                  fill out the form below, and our team will get back to you as
                  soon as possible. Your inquiries are important to us!
                </p>
              </div>
              <div className="enquiry-form row">
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-6 ps-lg-4 mt-md-4 mt-lg-0 mt-4">
              <div className="main-heading">
                <span>Contact Info</span>
                <h2>For Any Inquiries</h2>
                <p className="mb-4 mt-0 py-0">
                  Please use the following contact information:
                </p>
              </div>
              <div className="conatct-link">
                <div className="box">
                  <div className="icon">
                    <IoMail />
                  </div>
                  <div className="links">
                    <a href="mailto:info@mathillsgroup.com">
                      info@mathillsgroup.com
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="icon">
                    <IoIosCall />
                  </div>
                  <div className="links">
                    <a href="tel:+919205781326">+91 92057 81326</a>
                    {/* <a href="tel:+919205781322">+91 92057 81322</a> */}
                  </div>
                </div>
                <div className="box">
                  <div className="icon">
                    <FaLocationDot />
                  </div>
                  <div className="links">
                    <a href="javascript:void(0)" rel="noopener noreferrer">
                      B- 2, Vatika Mindscapes,
                      <br />
                      NH- 2 Mathura Road Faridabad,
                      <br /> Haryana - 121 003, INDIA
                      {/* Vatika Mindscape Building, 12th Floor, Tower A,
                      <br /> Business Plus, Main Mathura Road, Near Sarai Toll
                      Plaza,<br /> Faridabad INDIA. */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
