import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import SiteVerification from "../../assets/img/application/Site-Verification.png";
import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";

import Implementation from "../../assets/img/how-we-work/Implementation.png";
import InitialConsultation from "../../assets/img/how-we-work/Initial-consultation.png";
import BuildBrand from "../../assets/img/marketingandpromotions/Build brand awareness and recognition across target markets..png";
import Demographic from "../../assets/img/marketingandpromotions/Demographic Profile.png";
import Drivefoot from "../../assets/img/marketingandpromotions/Drive foot traffic and online sales for franchise locations..png";
import TargetMarketAnalysis from "../../assets/img/marketingandpromotions/Target Market Analysis.jpg";
import LeftImg from "../../assets/img/marketingandpromotions/marketing and promotions.jpg";
import Behavioral from "../../assets/img/marketingandpromotions/Behavioral Profile.png";
import Establish from "../../assets/img/marketingandpromotions/Establish customer loyalty and increase repeat business..png";
import GenrateLeads from "../../assets/img/marketingandpromotions/Generate leads and attract potential franchisees..png";
import Geographic from "../../assets/img/marketingandpromotions/Geographic Profile.png";
import Digital from "../../assets/img/marketingandpromotions/Digital Marketing Strategy.png";
import Local from "../../assets/img/marketingandpromotions/Local Store Marketing Strategy.png";
import National from "../../assets/img/marketingandpromotions/National Advertising Strategy.png";

export default function MarketingPromotions() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing marketing-promotions-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={TargetMarketAnalysis}
                className="img-fluid border-radius mb-3 mb-lg-0"
                alt="Marketing & Promotions"
              />
            </div>
            <div className="col-lg-6">
              <div className="main-heading">
                <span>Business Growth</span>
                <h2>Market Analysis for Strategic Decision-Making</h2>
              </div>
              <p className="mb-3">
                SuperKirana provides its franchisees with professional branding
                and marketing support. This includes everything from store
                signage and promotional materials to digital marketing
                campaigns. The unified branding across all SuperKirana stores
                helps build brand recognition and customer loyalty.
              </p>
              <p className="mb-3">
                In addition to national marketing campaigns, SuperKirana also
                supports localized marketing efforts, helping stores connect
                with their community through targeted promotions and events.
              </p>
              <p className="mb-3">
                The Superkirana Franchise Marketing Plan outlines the strategies
                and activities designed to promote the Superkirana brand,
                attract customers, and drive sales across all franchise
                locations. This plan focuses on both national and local
                marketing efforts, leveraging digital, social, and traditional
                media channels to maximize reach and impact.
              </p>
            </div>

            <div className="col-12 marketing-objectives-section transformation-process-section section-spacing-y">
              <div className="main-heading main-heading-center">
                <span>Marketing Objectives</span>
                <h2>Effective Strategies to Drive Growth and Awareness</h2>
              </div>
              <div className="marketing-objectives-box">
                <div className="marketing-objectives-card">
                  <span>
                    <img
                      src={BuildBrand}
                      className="img-fluid"
                      alt="Build brand awareness and recognition across target markets."
                    />
                  </span>

                  <p>
                    Build brand awareness and recognition across target markets.
                  </p>
                </div>
                <div className="marketing-objectives-card">
                  <span>
                    <img
                      src={GenrateLeads}
                      className="img-fluid"
                      alt="Genrate Leads"
                    />
                  </span>

                  <p>Generate leads and attract potential franchisees.</p>
                </div>
                <div className="marketing-objectives-card">
                  <span>
                    <img
                      src={Drivefoot}
                      className="img-fluid"
                      alt="Drive foot traffic and online sales for franchise locations."
                    />
                  </span>

                  <p>
                    Drive foot traffic and online sales for franchise locations.
                  </p>
                </div>
                <div className="marketing-objectives-card">
                  <span>
                    <img
                      src={Establish}
                      className="img-fluid"
                      alt=" Establish customer loyalty and increase repeat business."
                    />
                  </span>

                  <p>
                    Establish customer loyalty and increase repeat business.
                  </p>
                </div>
                <div className="marketing-objectives-card">
                  <span>
                    <img
                      src={SiteVerification}
                      className="img-fluid"
                      alt="Ongoing Support"
                    />
                  </span>

                  <p>Support franchisees with localized marketing efforts.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mb-4 mb-lg-0">
              <img src={LeftImg} className="img-fluid mb-2 left-img" alt="" />
              <div className="main-heading">
                <span>Target Market Analysis</span>
                <h2>Identifying Key Demographics and Market Behavior</h2>
              </div>
              <p>
                Understanding the target market is essential to creating
                effective marketing strategies. Superkirana's target market
                includes
              </p>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <div className="analysis-card">
                    <div className="icon">
                      <img
                        src={Demographic}
                        className="img-fluid"
                        alt="Demographic Profile"
                      />
                    </div>
                    <div className="content">
                      <h4>Demographic Profile</h4>
                      <p>
                        Primarily middle to upper-middle-class families, aged
                        25-55, with disposable income.
                      </p>
                    </div>
                  </div>
                  <div className="analysis-card">
                    <div className="icon">
                      <img
                        src={Geographic}
                        className="img-fluid"
                        alt="Geographic"
                      />
                    </div>
                    <div className="content">
                      <h4>Geographic Profile</h4>
                      <p>
                        Urban and suburban areas with high population density.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-lg-4 pt-lg-3">
                  <div className="analysis-card">
                    <div className="icon">
                      <img
                        src={Implementation}
                        className="img-fluid"
                        alt="Implementation"
                      />
                    </div>
                    <div className="content">
                      <h4>Psychographic Profile</h4>
                      <p>
                        Health-conscious, convenience-seeking individuals who
                        value quality and affordability.
                      </p>
                    </div>
                  </div>
                  <div className="analysis-card">
                    <div className="icon">
                      <img
                        src={Behavioral}
                        className="img-fluid"
                        alt="Behavioral"
                      />
                    </div>
                    <div className="content">
                      <h4>Behavioral Profile</h4>
                      <p>
                        Regular grocery shoppers who prefer convenience, loyalty
                        programs, and value-added services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="market-parallax section-spacing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="main-heading main-heading-center">
                <span>Branding and Positioning</span>
                {/* <h2>Heading</h2> */}
                <p>
                  Superkirana is positioned as a modern, convenient, and
                  customer-centric retail brand that transforms traditional
                  Kirana stores into efficient, well-stocked retail outlets. The
                  brand messaging emphasizes quality, convenience, and community
                  involvement, with a focus on delivering exceptional customer
                  experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-spacing strategies-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Marketing Strategies</span>
                <h2>Developing a Brand Strategy for Lasting Impressions</h2>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="strategies-card">
                <img
                  src={Digital}
                  className="img-fluid"
                  alt="Digital Marketing Strategy"
                />
                <div className="main-heading">
                  <span>Digital Marketing Strategy</span>
                </div>
                <p>
                  Superkirana will leverage digital marketing channels to reach
                  a broader audience, generate leads, and drive online and
                  in-store traffic. Key activities include:
                </p>
                <ul>
                  <li>
                    <p>
                      <b>Website and SEO:</b>
                      Optimize the Superkirana website for search engines to
                      increase organic traffic and improve user experience.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Social Media Marketing:</b>
                      Use platforms like Facebook, Instagram, and Twitter to
                      engage with customers, share content, and promote special
                      offers.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Email Marketing:</b>
                      Develop targeted email campaigns to keep customers
                      informed about promotions, new products, and loyalty
                      programs.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Pay-Per-Click Advertising:</b>
                      Invest in PPC campaigns on Google Ads and social media
                      platforms to drive targeted traffic to the website and
                      store locations.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Content Marketing:</b>
                      Create valuable content, such as blog posts, videos, and
                      infographics, to educate and engage customers.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="strategies-card">
                <img
                  src={Local}
                  className="img-fluid"
                  alt="Local Store Marketing Strategy"
                />
                <div className="main-heading">
                  <span>Local Store Marketing Strategy</span>
                </div>
                <p>
                  Each Superkirana franchise location will implement localized
                  marketing strategies to attract and retain customers in their
                  specific market. Key activities include:
                </p>
                <ul>
                  <li>
                    <p>
                      <b>Community Involvement:</b>
                      Participate in local events, sponsor community activities,
                      and support local charities to build goodwill and brand
                      recognition.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>In-Store Promotions:</b>
                      Offer discounts, loyalty programs, and special promotions
                      to encourage repeat visits and increase sales.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Local Advertising:</b>
                      Use local newspapers, radio, and direct mail to reach
                      potential customers within the store's geographic area.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Customer Referral Program:</b>
                      Encourage existing customers to refer friends and family
                      by offering incentives such as discounts or rewards.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="strategies-card">
                <img
                  src={National}
                  className="img-fluid"
                  alt="National Advertising Strategy"
                />
                <div className="main-heading">
                  <span>National Advertising Strategy</span>
                </div>
                <p>
                  Superkirana will invest in national advertising campaigns to
                  build brand awareness and drive franchise growth. Key
                  activities include:
                </p>
                <ul>
                  <li>
                    <p>
                      <b>Television and Radio Advertising:</b>
                      Run TV and radio ads in key markets to reach a broad
                      audience and build brand recognition.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Print Advertising:</b>
                      Place ads in national newspapers, magazines, and trade
                      publications to reach potential franchisees and customers.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Out-of-Home Advertising:</b>
                      Use billboards, transit ads, and other outdoor media to
                      increase brand visibility.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 section-spacing-y pb-0">
              <div className="main-heading">
                <span>Marketing Budget</span>
                {/* <h2>Heading</h2> */}
                <p>
                  A detailed marketing budget will be allocated to support the
                  strategies outlined in this plan. The budget will include
                  expenses for digital marketing, local store marketing,
                  national advertising, and other promotional activities.
                  Franchisees will be required to contribute a percentage of
                  their revenue to the national marketing fund, which will be
                  used to support these efforts.
                </p>
              </div>
            </div>
            <div className="col-12 section-spacing-y pb-0">
              <div className="main-heading">
                <span>Monitoring and Evaluation</span>
                {/* <h2>Heading</h2> */}
                <p className="mb-3">
                  The success of the marketing plan will be regularly monitored
                  and evaluated through key performance indicators (KPIs) such
                  as website traffic, social media engagement, customer
                  acquisition, and sales growth. Adjustments will be made as
                  necessary to ensure that marketing efforts are aligned with
                  business goals and market conditions.
                </p>
                <p>
                  The Superkirana Franchise Marketing Plan provides a
                  comprehensive approach to building brand awareness, attracting
                  customers, and driving sales across all franchise locations.
                  By implementing the strategies outlined in this plan,
                  Superkirana aims to establish itself as a leading retail brand
                  in the market and support the success of its franchisees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
