import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import NoImg from "../../assets/img/no-image.jpg";
import SuccessStoriesimg from "../../assets/img/success-stories/Success Stories.jpg";
export default function SuccessStories() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing success-stories-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Success Stories</span>
                <h2>Real-Life Success Stories from Our Franchise Network</h2>
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="story-card d-lg-flex d-block">
                <div className="image successstoryimgphn">
                  <img src={SuccessStoriesimg} className="img-fluid" alt="Success Stories" />
                </div>
                <div className="content">
                  <h2>Rahul’s Transformation</h2>
                  <b>Doubling Sales in Six Months</b>
                  <p>
                    <b>Background:</b> Rahul, a Kirana store owner in Delhi,
                    struggled with inventory management and attracting new
                    customers. Despite his hard work, he found it challenging to
                    keep his store profitable.
                  </p>
                  <p>
                    <b>Solution:</b> Rahul joined the SuperKirana network and
                    leveraged our advanced inventory management system, customer
                    engagement tools, and comprehensive support.
                  </p>
                  <p>
                    <b>Results:</b>
                    Within six months, Rahul’s store saw a 50% increase in
                    sales. The efficient inventory system reduced wastage and
                    stockouts, while targeted marketing campaigns attracted new
                    customers.
                  </p>
                  <p>
                    <b>Quote:</b>
                    SuperKirana transformed my business. The tools and support
                    provided were exactly what I needed to double my sales and
                    run my store more efficiently.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-12 mb-4">
              <div className="story-card story-card-two">
                <div className="image">
                  <img src={NoImg} className="img-fluid" alt="" />
                </div>
                <div className="content">
                  <h2>Title</h2>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Eveniet, inventore quo dolor voluptatibus officia animi nam
                    quisquam adipisci ducimus impedit minus alias quis doloribus
                    non odio fugiat aut quod commodi.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Omnis, hic repellendus ab accusantium quasi explicabo ad,
                    sit quisquam eaque ea nemo. Quas necessitatibus sed
                    inventore eum? Corrupti excepturi minus doloribus.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
