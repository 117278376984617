import { React, useEffect, useState } from "react";
import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Button } from "react-bootstrap";
import Notiflix from "notiflix";
import PostApiCall from "../../APi/PostApi";
import { IoSync } from "react-icons/io5";
import "../../Components/Home/Header/Header.css";
export default function ContactUs() {
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    city: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
      .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 90000000)
        .toString(36)
        [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  return (
    <>
      <div className="enquiry-form row">
        <div className="col-lg-6 mb-3">
          <Input
            placeholder="Full Name*"
            name="name"
            value={Enquiry.name}
            onChange={(e) =>
              setEnquiry({
                ...Enquiry,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-lg-6 mb-3">
          <Input
            placeholder="Email*"
            name="email"
            value={Enquiry.email}
            onChange={(e) =>
              setEnquiry({
                ...Enquiry,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-lg-6 mb-3">
          <Input
            placeholder="Mobile No.*"
            name="mobile"
            value={Enquiry.mobile}
            onChange={(e) => {
              if (e.target.value.length <= 10)
                setEnquiry({
                  ...Enquiry,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
            }}
          />
        </div>
        <div className="col-lg-6 mb-3">
          <Input
            placeholder="State*"
            name="city"
            value={Enquiry.city}
            onChange={(e) =>
              setEnquiry({
                ...Enquiry,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-12 mb-3">
          <TextArea
            rows={4}
            placeholder="Message"
            name="message"
            value={Enquiry.message}
            onChange={(e) =>
              setEnquiry({
                ...Enquiry,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-6 mb-3">
          <p className="me-2 mb-2 d-flex justify-content-between captcha-contact-value-size text-dark px-2">
            {captcha_number}
            <span
              onClick={() => {
                setRotatecaptcha("iorotate");
                Genratecaptcha();
              }}
              className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
            >
              <IoSync className="aContactButton text-dark" />
            </span>
          </p>
        </div>
        <div className="col-6 mb-3">
          <Input
            placeholder="Enter Captcha*"
            name="captcha"
            value={Enquiry.captcha}
            onChange={(e) =>
              setEnquiry({
                ...Enquiry,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="col-12">
          <Button
            className="theme-btn"
            onClick={() => {
              if (Enquiry.name != "") {
                if (Enquiry.email != "") {
                  if (Enquiry.EmailRegex.test(Enquiry.email)) {
                    if (Enquiry.mobile != "") {
                      if (Enquiry.mobile.length == 10) {
                        if (Enquiry.city != "") {
                          // if (Enquiry.message != "") {
                          if (
                            Enquiry.captcha.toString() ==
                            captcha_number.toString()
                          ) {
                            Notiflix.Loading.arrows("Please wait...");
                            PostApiCall.postRequest(
                              {
                                name: Enquiry.name,
                                mobile: Enquiry.mobile,
                                email: Enquiry.email,
                                message: Enquiry.message,
                                city: Enquiry.city,
                                clientid: 2072,
                              },
                              "SuperkiranaEnquiry"
                            ).then((results2) =>
                              results2.json().then((obj2) => {
                                if (
                                  results2.status == 200 ||
                                  results2.status == 201
                                ) {
                                  setLoader(false);
                                  Notiflix.Notify.success(
                                    "Thank you, our team will contact you shortly!"
                                  );
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 2000);
                                } else Notiflix.Loading.remove();
                              })
                            );
                          } else {
                            Notiflix.Notify.failure(
                              "Please enter valid captcha"
                            );
                          }
                        }
                        //    else {
                        //     Notiflix.Notify.failure(
                        //       "Please enter message"
                        //     );
                        //   }
                        // }
                        else {
                          Notiflix.Notify.failure("Please enter state");
                        }
                      } else {
                        Notiflix.Notify.failure(
                          "Please enter valid mobile number"
                        );
                      }
                    } else {
                      Notiflix.Notify.failure("Please enter mobile number");
                    }
                  } else {
                    Notiflix.Notify.failure("Please enter valid email");
                  }
                } else {
                  Notiflix.Notify.failure("Please enter email");
                }
              } else {
                Notiflix.Notify.failure("Please enter name");
              }
            }}
          >
            {Loader == true ? (
              <div class="d-flex justify-content-center">
                <div
                  class="spinner-border"
                  role="status"
                  style={{ width: "1.4rem", height: "1.4rem" }}
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <span>Submit </span>
            )}
          </Button>
        </div>
        {/* <iframe
          src="https://globaltrendz.info/superkiranaenquiry/2072/enquiry"
          frameborder="0"
        ></iframe> */}
      </div>
    </>
  );
}
