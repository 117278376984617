import { Link } from "react-router-dom";
import "./ParallaxSection.css";
export default function ParallaxSection() {
  return (
    <section className="parallax-section section-spacing">
      <div className="main-heading mb-0 main-heading-center text-white">
        <span className="px-2">
          {/* Joining the SuperKirana franchise network opens up a world of
          opportunities for Kirana store owners. */}
          Empowering Your Kirana Business with Capital Financing
        </span>
        {/* <h2 className="mb-0">
          Explore the benefits of becoming a part of our growing network.
        </h2> */}
        <p className="px-2">
          SuperKirana offers more than just franchise opportunities — we help you grow with easy financing solutions that no other franchise provides.
        </p>
        <p className="px-2">
          With Fintech and Supply Chain Finance, we give you the financial support needed for inventory management and business expansion. This sets us apart, making sure you succeed every step of the way.
        </p>
        <p className="mb-3">
          Join SuperKirana and let’s grow together.
        </p>
        <div className="d-flex justify-content-center mb-4">
          <a href="tel:+919205781326" className="theme-btn">
            Call Us Now
          </a>
        </div>
      </div>
    </section>
  );
}
