import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import IncreasedSales from "../../assets/img/why-us/Increased-Sales.png";
import FinancialBenefits from "../../assets/img/franchisebenifits/Financial Benefits.jpg";
import OperationalSupport from "../../assets/img/franchisebenifits/Operational Support.jpg";
import MarketingandBranding from "../../assets/img/franchisebenifits/Marketing and Branding.jpg";
import TechnologyIntegration from "../../assets/img/franchisebenifits/Technology Integration.jpg";
import CommunityEngagement from '../../assets/img/franchisebenifits/Community Engagement.jpg';
import InvestmentFinancials from "../../assets/img/franchisebenifits/Investment & Financials.jpg";
export default function FranchiseBenefits() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing franchise-benefits-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center mb-4">
                <span>Franchise Benefits</span>
                <h2>Extensive Advantages of Partnering with SuperKirana</h2>
                <p>
                  Joining the SuperKirana network comes with a host of benefits
                  designed to ensure the success of your store. Here’s what you
                  can expect:
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <img
                src={FinancialBenefits}
                className="img-fluid border-radius"
                alt="Financial Benefits"
              />
            </div>
            <div className="col-lg-6 mb-4">
              <div className="main-heading mb-3">
                <span>Financial Benefits:</span>
              </div>
              <div className="benifits-card">
                <p>
                  <b>High ROI:</b>
                  SuperKirana’s model offers a high return on investment with
                  efficient operations and strong marketing.
                </p>
                <p>
                  <b>Multiple Revenue Streams:</b>
                  Earn from product sales, promotions, and loyalty programs,
                  boosting profitability.
                </p>
                <p>
                  <b>Bulk Discounts:</b>
                  Enjoy savings from bulk purchasing, lowering costs and
                  increasing margins.
                </p>
              </div>
            </div>
          </div>
          <div className="row flex-lg-row-reverse">
            <div className="col-lg-6 mb-4">
              <img
                src={OperationalSupport}
                className="img-fluid border-radius"
                alt="OperationalSupport"
              />
            </div>
            <div className="col-lg-6 mb-4">
              <div className="main-heading mb-3">
                <span>Operational Support:</span>
              </div>
              <div className="benifits-card">
                {/* <img src={IncreasedSales} className="img-fluid" alt="" /> */}

                <p>
                  <b>Comprehensive Training:</b>
                  SuperKirana offers in-depth training for you and your staff on
                  customer service and inventory management, ensuring smooth
                  operations from day one.
                </p>
                <p>
                  <b>Ongoing Assistance:</b>
                  We provide continuous support with regular visits, performance
                  reviews, and troubleshooting to help you overcome challenges
                  and optimize your store.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <img
                src={MarketingandBranding}
                className="img-fluid border-radius"
                alt=""
              />
            </div>
            <div className="col-lg-6 mb-4">
              <div className="main-heading mb-3">
                <span>Marketing and Branding:</span>
              </div>
              <div className="benifits-card">
                {/* <img src={IncreasedSales} className="img-fluid" alt="" /> */}

                <p>
                  <b>National and Local Campaigns:</b>
                  SuperKirana runs marketing campaigns nationwide and locally to
                  boost your store's visibility. You’ll receive professionally
                  designed materials like flyers, posters, and digital content.
                </p>
                <p>
                  <b>Loyalty Programs:</b>
                  Benefit from our proven customer loyalty programs to encourage
                  repeat business and build lasting customer relationships.
                </p>
              </div>
            </div>
          </div>
          <div className="row flex-lg-row-reverse">
            <div className="col-lg-6 mb-4">
              <img
                src={TechnologyIntegration}
                className="img-fluid border-radius"
                alt=""
              />
            </div>
            <div className="col-lg-6 mb-4">
              <div className="main-heading mb-3">
                <span>Technology Integration:</span>
              </div>
              <div className="benifits-card">
                {/* <img src={IncreasedSales} className="img-fluid" alt="" /> */}

                <p>
                  <b>Advanced POS Systems:</b>
                  SuperKirana stores use advanced POS systems to simplify
                  transactions, track sales, and manage inventory.
                </p>
                <p>
                  <b>Digital Payment Solutions:</b>
                  Accept various digital payment methods for fast and convenient
                  transactions.
                </p>
                <p>
                  <b>Customer Relationship Management (CRM):</b>
                  Our CRM tools provide insights into customer preferences for
                  personalized marketing and better service.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <img
                src={CommunityEngagement}
                className="img-fluid border-radius"
                alt=""
              />
            </div>
            <div className="col-lg-6 mb-4">
              <div className="main-heading mb-3">
                <span>Community Engagement:</span>
              </div>
              <div className="benifits-card">
                <p>
                  <b>Local Involvement:</b>
                  SuperKirana encourages franchisees to connect with local
                  communities through events, promotions, and sponsorships,
                  strengthening customer relationships and establishing your
                  store as a trusted local hub.
                </p>
                <p>
                  <b>Sustainability Initiatives:</b>
                  We promote eco-friendly practices, like reducing plastic use
                  and offering sustainable products, as part of our commitment
                  to sustainability.
                </p>
              </div>
            </div>
          </div>
          <div className="row flex-lg-row-reverse">
            <div className="col-lg-6 mb-4">
              <img
                src={InvestmentFinancials}
                className="img-fluid border-radius"
                alt="Investment & Financials"
              />
            </div>
            <div className="col-lg-6 mb-4">
              <div className="main-heading mb-3">
                <span>Investment & Financials:</span>
              </div>
              <div className="benifits-card">
                {/* <img src={IncreasedSales} className="img-fluid" alt="" /> */}

                <p>
                  The initial investment required to set up a SuperKirana
                  franchise varies depending on the chosen plan (Mini, Standard,
                  Premium, Executive). The investment covers the cost of store
                  setup, inventory, technology integration, and franchise fees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
