import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import Approval from "../../assets/img/application/approved.png";
import AdvancedPOS from "../../assets/img/inventorymanagement/Advanced POS and Inventory Management Systems.png";
import AutomatedInventory from "../../assets/img/inventorymanagement/Automated Inventory Ordering and Stocking.png";
import LowerOperational from "../../assets/img/inventorymanagement/Lower Operational Costs.png";
import OptimizedStock from "../../assets/img/inventorymanagement/Optimized Stock Levels.png";
import ReducedWastage from "../../assets/img/inventorymanagement/Reduced Wastage and Improved Efficiency.png";
import Streamlined from "../../assets/img/inventorymanagement/Streamlined Backend Operations.png";
import SupplyChain from "../../assets/img/inventorymanagement/Supply Chain Management.jpg";
export default function InventoryManagement() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing inventory-management-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={SupplyChain}
                className="img-fluid border-radius"
                alt="Real-Time Inventory Monitoring"
              />
            </div>
            <div className="col-lg-6">
              <div className="main-heading">
                <span>Real-Time Inventory Monitoring</span>
                <h2>Cost-Effective Inventory Strategies for Better Budget Management </h2>
                <p>
                  At Super Kirana, Mathills Private Limited provides
                  comprehensive supply chain and inventory management solutions
                  to ensure smooth operations for franchise stores. By
                  integrating advanced systems and efficient practices, Mathills
                  helps franchisees optimize their inventory and reduce
                  operational costs. As the trusted SCM partner for Super
                  Kirana, Mathills handles a wide range of grocery brands and
                  offers franchisees cutting-edge tools for efficient inventory
                  ordering and stocking.
                </p>
              </div>
            </div>
          </div>
          <div className="row section-spacing-y pb-0">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Key Benefits</span>
                <h2>Smart Inventory Alerts to Prevent Stockouts and Overstocking</h2>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="features-card">
                <img src={AdvancedPOS} className="img-fluid" alt="Advanced POS and Inventory Management Systems" />
                <h4>Advanced POS and Inventory Management Systems</h4>
                <p>
                  SuperKirana provides the best POS systems integrated with
                  inventory management software, enabling real-time tracking of
                  stock levels. Franchisees Owners can monitor sales, track
                  popular items, and ensure that inventory levels are
                  consistently optimized.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="features-card">
                <img src={LowerOperational} className="img-fluid" alt="Lower Operational Costs" />
                <h4>Lower Operational Costs</h4>
                <p>
                  Efficient stock control, reduced wastage, and automated
                  inventory tracking contribute to lower expenses and higher
                  profitability with achieving up to a 10% reduction in
                  operational costs.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="features-card">
                <img src={AutomatedInventory} className="img-fluid" alt="Automated Inventory Ordering and Stocking" />
                <h4>Automated Inventory Ordering and Stocking</h4>
                <p>
                  The inventory ordering and stocking system set up by Mathills
                  ensures seamless replenishment of products. The system
                  automatically generates orders based on sales patterns and
                  stock levels, reducing manual effort and preventing
                  overstocking or stockouts.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="features-card">
                <img src={OptimizedStock} className="img-fluid" alt="Optimized Stock Levels" />
                <h4>Optimized Stock Levels</h4>
                <p>
                  Mathills’ inventory management system helps franchisees
                  maintain optimal stock levels, ensuring that fast-moving
                  products are always available while minimizing excess stock of
                  slow-moving items.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="features-card">
                <img src={Streamlined} className="img-fluid" alt="Streamlined Backend Operations" />
                <h4>Streamlined Backend Operations</h4>
                <p>
                  Mathills handles all backend operations, including supplier
                  coordination and logistics, ensuring that franchisees receive
                  products on time and at the best rates. This allows franchise
                  owners to focus on running the store while Mathills takes care
                  of inventory needs.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="features-card">
                <img src={ReducedWastage} className="img-fluid" alt="Reduced Wastage and Improved Efficiency" />
                <h4>Reduced Wastage and Improved Efficiency</h4>
                <p>
                  By leveraging data-driven insights and precise stock tracking,
                  Mathills helps franchisees reduce wastage and enhance overall
                  operational efficiency. This results in better inventory
                  turnover and increased profitability for the stores.
                </p>
              </div>
            </div>
            <div className="col-12">
              <p>
                With Mathills Private Limited managing inventory and supply
                chain operations, Super Kirana franchisees can benefit from
                lower operational costs, advanced inventory systems, and
                reliable backend support. This ensures a smooth, efficient
                operation and more time to focus on growing the business.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
