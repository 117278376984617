import { Link, useLocation } from "react-router-dom";
import "./InnerBanner.css";
export default function InnerBanner(props) {
  let location = useLocation();
  return (
    <section
      className="inner-banner section-spacing"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url("${
          props?.bgImage != undefined
            ? props?.bgImage
            : props.pageData?.bgImageBanner
        }")`,
      }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>
              {props.pageData != null
                ? props.pageData.title
                : String(location.pathname)
                    .replace("/", "")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("-", " ")
                    .replace("-", " ")}
            </h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="mx-2">/</li>
              <li>
                <p>
                  {props.pageData != null
                    ? props.pageData.title
                    : String(location.pathname)
                        .replace("/", "")
                        .replace("-", " ")
                        .replace("-", " ")
                        .replace("-", " ")
                        .replace("-", " ")
                        .replace("-", " ")
                        .replace("-", " ")
                        .replace("-", " ")
                        .replace("-", " ")
                        .replace("-", " ")
                        .replace("-", " ")
                        .replace("-", " ")}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
