import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import One from "../../../assets/img/success-stories/one.jpeg";
import Two from "../../../assets/img/success-stories/two.jpeg";
import Three from "../../../assets/img/success-stories/three.jpeg";
import StoryBanner from "../../../assets/img/success-stories/left-banner.jpeg";
import "./SuccessStories.css";
import Priyaimg from "../../../assets/img/success-stories/priya1.png";
import Meenaimg from "../../../assets/img/success-stories/meena1.png";
import Aanandimg from "../../../assets/img/success-stories/annad1.png";
export default function SuccessStories() {
  return (
    <section className="success-stories-section section-spacing">
      <img src={StoryBanner} className="img-fluid banner-img" alt="" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-7 ms-auto">
            <div className="main-heading">
              <span>Success Stories</span>
              <h2>
                Real stories from Kirana owners who have joined SuperKirana and
                seen their stores thrive.
              </h2>
            </div>
          </div>
          <div className="col-lg-9 ms-auto">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              loop={true}
              breakpoints={{
                700: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1430: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              modules={[Pagination]}
              className="mySwiper pb-5 pb-lg-2">
              <SwiperSlide>
                <div className="story-card">
                  <img src={Priyaimg} className="img-fluid" alt="success-stories" />
                  <div className="content home-sucees-story-card">
                    <p>
                      Priya became a SuperKirana franchisee and received extensive training, operational support, and access to high-quality products.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="story-card">
                  <img src={Meenaimg} className="img-fluid" alt="success-stories" />
                  <div className="content home-sucees-story-card">
                    <p>
                      Meena joined the SuperKirana community and utilized our customer engagement strategies, including loyalty programs and personalized promotions.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="story-card">
                  <img src={Aanandimg} className="img-fluid" alt="" />

                  <div className="content home-sucees-story-card">
                    <p>
                      Anand partnered with SuperKirana and implemented our AI-powered inventory management system, along with other advanced technological tools.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
