import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import IncreasedSales from "../../assets/img/why-us/Increased-Sales.png";
import Module1 from "../../assets/img/franchisesupport/Module 1 Orientation and Introduction to Superkirana.png";
import Module2 from "../../assets/img/franchisesupport/Module 2 Store Setup and Design.png";
import Module3 from "../../assets/img/franchisesupport/Module 3 Daily Operations and Inventory Management.png";
import Module4 from "../../assets/img/franchisesupport/Module 4 Customer Service Excellence.png";
import Module5 from "../../assets/img/franchisesupport/Module 5 Financial Management and Reporting.png";
import Module6 from "../../assets/img/franchisesupport/Module 6 Technology and Systems Training.png";
import Module7 from "../../assets/img/franchisesupport/Module 7 Staff Training and Development.png";
export default function FranchiseSupportTraining() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing franchise-support-training-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="main-heading main-heading-center">
                <span>Franchise Support & Training</span>
                <h2>Comprehensive Assistance and Development Programs for Franchise Success</h2>
                <p>
                  Superkirana provides comprehensive training and support to all
                  franchisees. This includes initial training before the store
                  opens, ongoing support, and access to our proprietary systems
                  and tools. Our training program covers all aspects of running
                  a Superkirana store, including operations, marketing, customer
                  service, and financial management.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="main-heading">
                <span>Superkirana Franchise Training Program Outline</span>
                <h2> Introduction to the Training Program</h2>
                <p>
                  Welcome to the Superkirana Franchise Training Program. This
                  program is designed to provide new franchisees with the
                  knowledge, skills, and resources needed to successfully
                  operate a Superkirana store. The training program covers all
                  aspects of store operations, from setup and daily management
                  to customer service and financial management. This outline
                  provides an overview of the training modules, their content,
                  and the schedule.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="main-heading">
                <span>Training Program Objectives</span>
                <h2>
                  The objectives of the Superkirana Franchise Training Program
                  are to
                </h2>
              </div>
              <p>
                1. Equip franchisees with the operational knowledge necessary to
                manage a Superkirana store.
              </p>
              <p>
                2. Provide hands-on experience in using Superkirana’s systems
                and tools.
              </p>
              <p>
                3. Develop strong customer service and staff management skills.
              </p>
              <p>
                4. Ensure compliance with Superkirana’s standards and
                procedures.
              </p>
              <p>
                5. Foster a commitment to continuous improvement and excellence
                in store operations.
              </p>
            </div>
            <div className="col-12 section-spacing-y pb-0">
              <div className="main-heading main-heading-center">
                <span>Training Modules Overview</span>
                <h2>
                  The Superkirana Franchise Training Program is divided into the
                  following key modules:
                </h2>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="modules-card">
                <div className="icon">
                  <img src={Module1} className="img-fluid" alt="Orientation and Introduction to Superkirana" />
                </div>
                <h4>Module 1: Orientation and Introduction to Superkirana</h4>
                <b>Duration: 1 Day</b>
                <b> Content:</b>
                <ul>
                  <li>
                    <p>Welcome and Introduction to Superkirana</p>
                  </li>
                  <li>
                    <p>Overview of the Superkirana Franchise System</p>
                  </li>
                  <li>
                    <p>Understanding the Superkirana Brand and Values</p>
                  </li>
                  <li>
                    <p>Tour of a Superkirana Store</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="modules-card">
                <div className="icon">
                  <img src={Module2} className="img-fluid" alt=" Store Setup and Design" />
                </div>
                <h4>Module 2: Store Setup and Design</h4>
                <b>Duration: 2 Days</b>
                <b> Content:</b>
                <ul>
                  <li>
                    <p>Site Selection and Lease Negotiation</p>
                  </li>
                  <li>
                    <p>Store Layout and Design Principles</p>
                  </li>
                  <li>
                    <p>Equipment and Fixtures Installation</p>
                  </li>
                  <li>
                    <p>Branding and Signage Guidelines</p>
                  </li>
                  <li>
                    <p>Health and Safety Compliance</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="modules-card">
                <div className="icon">
                  <img src={Module3} className="img-fluid" alt="Daily Operations and Inventory Management" />
                </div>
                <h4>Module 3: Daily Operations and Inventory Management</h4>
                <b>Duration: 3 Days</b>
                <b> Content:</b>
                <ul>
                  <li>
                    <p>Opening and Closing Procedures</p>
                  </li>
                  <li>
                    <p>Cash Handling and Register Management</p>
                  </li>
                  <li>
                    <p>Inventory Ordering and Stocking</p>
                  </li>
                  <li>
                    <p>Product Display and Merchandising</p>
                  </li>
                  <li>
                    <p>Handling Returns and Exchanges</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="modules-card">
                <div className="icon">
                  <img src={Module4} className="img-fluid" alt="Customer Service Excellence" />
                </div>
                <h4>Module 4: Customer Service Excellence</h4>
                <b>Duration: 1 Day</b>
                <b> Content:</b>
                <ul>
                  <li>
                    <p>Customer Service Principles</p>
                  </li>
                  <li>
                    <p>Managing Customer Complaints and Feedback</p>
                  </li>
                  <li>
                    <p>Building Long-Term Customer Relationships</p>
                  </li>
                  <li>
                    <p>Implementing Customer Loyalty Programs</p>
                  </li>
                  <li>
                    <p>Enhancing the Customer Experience</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="modules-card">
                <div className="icon">
                  <img src={Module5} className="img-fluid" alt="Financial Management and Reporting" />
                </div>
                <h4>Module 5: Financial Management and Reporting</h4>
                <b>Duration: 2 Days</b>
                <b> Content:</b>
                <ul>
                  <li>
                    <p>Budgeting and Forecasting for Franchisees</p>
                  </li>
                  <li>
                    <p>Understanding Financial Statements</p>
                  </li>
                  <li>
                    <p>Managing Expenses and Cash Flow</p>
                  </li>
                  <li>
                    <p>Pricing Strategies and Profitability Analysis</p>
                  </li>
                  <li>
                    <p>Reporting Requirements and Tools</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="modules-card">
                <div className="icon">
                  <img src={Module6} className="img-fluid" alt="Technology and Systems Training" />
                </div>
                <h4>Module 6: Technology and Systems Training</h4>
                <b>Duration: 2 Days</b>
                <b> Content:</b>
                <ul>
                  <li>
                    <p>Introduction to Superkirana’s POS Systems</p>
                  </li>
                  <li>
                    <p>Using Inventory Management Software</p>
                  </li>
                  <li>
                    <p>Online Ordering and Delivery Systems</p>
                  </li>
                  <li>
                    <p>Troubleshooting Common Technology Issues</p>
                  </li>
                  <li>
                    <p>Data Security Best Practices</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="modules-card">
                <div className="icon">
                  <img src={Module7} className="img-fluid" alt="Staff Training and Development" />
                </div>
                <h4>Module 7: Staff Training and Development</h4>
                <b>Duration: 1 Day</b>
                <b> Content:</b>
                <ul>
                  <li>
                    <p>Recruiting and Onboarding Staff</p>
                  </li>
                  <li>
                    <p>Training Employees in Store Operations</p>
                  </li>
                  <li>
                    <p>Performance Management and Reviews</p>
                  </li>
                  <li>
                    <p>Health and Safety Training for Staff</p>
                  </li>
                  <li>
                    <p>Building a Positive Work Environment</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-lg-6">
              <div className="main-heading ">
                <span>Ongoing Support and Advanced Training</span>
                {/* <h2>Heading</h2> */}
              </div>
              <p>
                After completing the initial training program, franchisees will
                receive ongoing support from the Superkirana team. Advanced
                training sessions are available to help franchisees enhance
                their skills and adapt to changes in the retail environment.
                Ongoing support includes access to online resources, regular
                updates on best practices, and the opportunity to participate in
                regional and national training events.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="main-heading">
                <span>Training Evaluation and Feedback</span>
                {/* <h2>Heading</h2> */}
              </div>
              <p>
                To ensure the effectiveness of the training program, we
                regularly seek feedback from franchisees. Franchisees are
                encouraged to provide input on the training modules, suggest
                areas for improvement, and share their experiences. This
                feedback helps us continuously improve the training program and
                better support our franchisees.
              </p>
            </div>
            <div className="col-12 pt-4">
              <div className="main-heading">
                <span>Conclusion</span>
                <p>
                  The Superkirana Franchise Training Program is an essential
                  part of your journey as a franchisee. By participating fully
                  in the program, you will gain the knowledge, skills, and
                  confidence needed to successfully operate your Superkirana
                  store. We look forward to working with you and supporting your
                  success in the Superkirana network.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

