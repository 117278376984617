import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../Components/InnerBanner/InnerBanner";
export default function NewsMedia() {
  return (
    <>
    <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing news-media-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>News & Media</span>
                <h2>Heading</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
