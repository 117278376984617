export default function FranchisseSuppport() {
  return (
    <section className="services-section section-spacing pb-md-4 pb-0 mb-0 ">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="main-heading">
              <h2 className="fs-4 mb-0 pb-0">SuperKirana Franchisee Support</h2>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <ul className="service-support-list ps-3">
              <li>
                <p className="about-us-content">
                  <strong>30 days</strong> store launch guarantee.
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  Simple <strong>billing software</strong> for smooth
                  operations.
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  <strong>Free 3-month accounting</strong> for business support
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  <strong>Free GST Registration</strong> and{" "}
                  <strong>GST filing for 6 months</strong> to support store
                  support activity.
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  <strong>No Royalty fees</strong> for initial 6 Months with Key
                  account manager support.
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  <strong>Advanced expiry management</strong> for stock near to
                  expiry automatic offers applied.
                </p>
              </li>

              <li>
                <p className="about-us-content">
                  <strong>Pan-India stock delivery</strong> from{" "}
                  <strong>27 locations.</strong>
                </p>
              </li>

              <li>
                <p className="about-us-content">
                  <strong>Digital marketing support</strong> with{" "}
                  <strong>Facebook & Instagram ads, YouTube promotion</strong>{" "}
                  and store visibility through multiple promotional channels.
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  <strong>Free 2-year business website</strong> for your setup
                  to know how the store visibility and presence.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <ul className="service-support-list ps-3">
              <li>
                <p className="about-us-content">
                  <strong>Full branding support</strong> flyers, banners,
                  danglers, hording, boarding and digital ads campaign for
                  store.
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  <strong>Working capital finance</strong> or Supply chain
                  finance facility for stock fulfilment under Fintech.
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  <strong> 30 days Stock Credit</strong> Facility under Fintech.
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  Help with Shop & Establishment Act registration also{" "}
                  <strong>
                    support in opening 24x7 open store after area verification.
                  </strong>
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  <strong>SuperKirana application support</strong> after
                  successful launch of store, Customer can order directly to
                  store through mobile application.
                </p>
              </li>
              <li>
                <p className="about-us-content">
                  <strong>Montly Stock Audit</strong> for continue check the
                  stock reports to minimise the expiry and check the demanding
                  products.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
