import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../Components/InnerBanner/InnerBanner";
export default function TermsAndConditions() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing static-page disclaimer-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Terms And Conditions</span>
                {/* <h2>Heading</h2> */}
              </div>
              <p>
                Welcome to SuperKirana. These terms and conditions outline the
                rules and regulations for the use of SuperKirana's website and
                services. By accessing this website, you agree to comply with
                and be bound by the following terms and conditions. If you
                disagree with any part of these terms and conditions, please do
                not use our website.
              </p>
              <h4>Definitions</h4>
              <p>
                "Website" refers to the SuperKirana website
                (www.superkirana.in).
              </p>
              <p>
                "We," "us," "our" refers to SuperKirana and Mathills Private
                Limited.
              </p>
              <p>
                "You," "user," refers to the person accessing or using our
                website and services.
              </p>
              <p>
                "Services" refers to all services provided by SuperKirana,
                including franchise opportunities, partnerships, community
                support, and resources.
              </p>
              <h4>Acceptance of Terms</h4>
              <p>
                By using our website and services, you agree to be bound by
                these terms and conditions. If you do not agree to these terms,
                please refrain from using our website and services.
              </p>
              <h4>Use of the Website</h4>
              <p>
                <b>Eligibility:</b> You must be at least 18 years old to use our
                website and services. User Account: To access certain features
                of our website, you may be required to create an account. You
                are responsible for maintaining the confidentiality of your
                account information and for all activities that occur under your
                account.
              </p>
              <p>
                <b>Prohibited Activities:</b> You agree not to use the website
                for any unlawful purpose or in any way that could harm or
                disrupt our services. Prohibited activities include, but are not
                limited to:
              </p>
              <ul>
                <li>
                  <p>Uploading or distributing malicious software or content</p>
                </li>
                <li>
                  <p>Engaging in fraudulent or deceptive practices</p>
                </li>
                <li>
                  <p>
                    Infringing on the intellectual property rights of others
                  </p>
                </li>
              </ul>
              <h4>Intellectual Property</h4>
              <p>
                <b>Ownership:</b> All content, trademarks, logos, and
                intellectual property on the website are owned by SuperKirana
                and Mathills Private Limited or their licensors. Unauthorized
                use of any content on the website is prohibited
              </p>
              <p>
                <b>License:</b> You are granted a limited, non-exclusive,
                non-transferable license to access and use the website for
                personal, non-commercial purposes. This license does not permit
                you to reproduce, distribute, or create derivative works from
                any content on the website.
              </p>
              <h4>Privacy Policy</h4>
              <p>
                Your use of our website is also governed by our Privacy Policy,
                which outlines how we collect, use, and protect your personal
                information. By using our website, you consent to the collection
                and use of your information as described in the Privacy Policy.
              </p>
              <h4>Disclaimers</h4>
              <p>
                <b>No Warranties:</b> The website and services are provided on
                an "as is" and "as available" basis. SuperKirana makes no
                warranties, express or implied, regarding the website or
                services, including but not limited to, warranties of
                merchantability, fitness for a particular purpose, and
                non-infringement.
              </p>
              <p>
                <b>Limitation of Liability:</b> SuperKirana and its affiliates
                will not be liable for any direct, indirect, incidental,
                consequential, or punitive damages arising from your use of the
                website or services, even if we have been advised of the
                possibility of such damages.
              </p>
              <h4>Changes to Terms and Conditions</h4>
              <p>
                SuperKirana reserves the right to modify these terms and
                conditions at any time. Any changes will be effective
                immediately upon posting on the website. Your continued use of
                the website after the posting of changes constitutes your
                acceptance of the revised terms and conditions.
              </p>
              <h4>Governing Law</h4>
              <p>
                These terms and conditions are governed by and construed in
                accordance with the laws of India. Any disputes arising out of
                or related to these terms and conditions will be subject to the
                exclusive jurisdiction of the courts in Faridabad, Haryana,
                India.
              </p>
              <h4>Contact Information</h4>
              <p>
                If you have any questions or concerns about these terms and
                conditions, please contact us:
              </p>
              <p>
                <b>Address:</b> B- 2, Vatika Mindscapes,NH- 2 Mathura Road Faridabad, Haryana- 121003, INDIA.
              </p>
              <p>
                Phone: <a href="tel:+919205781326"> +91 92057 81326</a>.{" "}
                {/* <a href="tel:+919205781322">+91 92057 81322</a>. */}
              </p>
              <p>
                <b>Email:</b>{" "}
                <a href="mailto:info@superkirana.com">info@superkirana.com.</a>
              </p>
              <h4>Termination</h4>
              <p>
                SuperKirana reserves the right to terminate your access to the
                website and services at any time, without notice, for conduct
                that we believe violates these terms and conditions or is
                harmful to other users of the website or services.
              </p>
              <h4>Entire Agreement</h4>
              <p>
                These terms and conditions, together with our Privacy Policy,
                constitute the entire agreement between you and SuperKirana
                regarding your use of the website and services and supersede any
                prior agreements or understandings.
              </p>
              <h4>Severability</h4>
              <p>
                If any provision of these terms and conditions is found to be
                invalid or unenforceable, the remaining provisions will continue
                to be valid and enforceable to the fullest extent permitted by
                law.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
