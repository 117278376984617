import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import "./MainNavbar.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import Logo from "../../assets/img/logo/superkirana-logo.png";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { IoIosArrowDown, IoIosCall } from "react-icons/io";
import { FaSquareInstagram } from "react-icons/fa6";
import { IoMail, IoReorderThreeOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
// For download brochure
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PostApiCall from "../../APi/PostApi";
import Notiflix from "notiflix";
import { IoSync } from "react-icons/io5";
import { Input } from "antd";
import Brochure from "../../assets/pdf/Super Kirana Brochure (1).pdf";

export default function MainNavbar() {
  const { pathname } = useLocation();

  // Model for brochure
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);

  const handleDownload = async () => {
    const errorMessages = [];

    if (!Enquiry.name) {
      errorMessages.push("Please enter your name.");
    } else if (!Enquiry.email) {
      errorMessages.push("Please enter your email.");
    } else if (!Enquiry.EmailRegex.test(Enquiry.email)) {
      errorMessages.push("Please enter a valid email.");
    } else if (!Enquiry.mobile) {
      errorMessages.push("Please enter your mobile number.");
    } else if (Enquiry.mobile.length !== 10) {
      errorMessages.push("Please enter a valid mobile number (10 digits).");
    }
    if (errorMessages.length > 0) {
      errorMessages.forEach(message => Notiflix.Notify.failure(message));
      return;
    }
    Notiflix.Loading.arrows("Please wait...");

    try {
      const response = await PostApiCall.postRequest(
        {
          name: Enquiry.name,
          mobile: Enquiry.mobile,
          email: Enquiry.email,
          clientid: 2072,
          mailtype: "New Brochure Download"
        },
        "SuperkiranaBrochureEnquiry"
      );

      if (response.status === 200 || response.status === 201) {
        Notiflix.Notify.success("Thank You for Downloading Our Brochure");
        const link = document.createElement("a");
        link.href = Brochure;
        link.setAttribute("download", "Super Kirana Brochure.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // Close modal after download
        setTimeout(() => {
          handleClose();
          window.location.reload();
        }, 2000);
      } else {
        Notiflix.Notify.failure("There was an issue, please try again.");
      }
    } catch (error) {
      Notiflix.Notify.failure("Error occurred, please try again later.");
    } finally {
      Notiflix.Loading.remove();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const items1 = [
    {
      label: <a className="text-white" href="/market-overview">Market Overview</a>,
      key: "1",
    },
    {
      label: <a className="text-white" href="/franchise-models">Franchise Models</a>,
      key: "2",
    },
    {
      label: (
        <a className="text-white" href="/investment-and-financials">
          Investment & Financials
        </a>
      ),
      key: "3",
    },
    {
      label: <a className="text-white" href="/franchise-benefits">Franchise Benefits</a>,
      key: "4",
    },
    {
      label: <a className="text-white" href="/site-selection">Site Selection</a>,
      key: "5",
    },
    {
      label: <a className="text-white" href="/success-stories">Success Stories</a>,
      key: "6",
    },
  ];

  const items2 = [
    {
      label: (
        <a className="text-white" href="/franchise-support-and-training">
          Franchise Support & Training
        </a>
      ),
      key: "1",
    },
    {
      label: (
        <a className="text-white" href="/supply-chain-management">
          Supply Chain Management
        </a>
      ),
      key: "2",
    },
    {
      label: (
        <a className="text-white" href="/inventory-management">Inventory Management</a>
      ),
      key: "3",
    },
    {
      label: (
        <a className="text-white" href="/marketing-and-promotions">Marketing & Promotions</a>
      ),
      key: "4",
    },
  ];
  return (
    <div className="main-navbar navbar-tab-view">
      <div className="container-fluid d-none d-lg-block">
        <div className="row">
          <div className="col-12">
            <div className="top-bar">
              <ul>
                <li>
                  <a href="mailto:info@mathillsgroup.com">
                    <IoMail />
                    info@mathillsgroup.com
                  </a>
                </li>
                <li>
                  <a href="tel:+919205781326">
                    <IoIosCall /> +91 92057 81326
                  </a>
                </li>
                <li className="p-0">
                  <ul className="sm-links">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=61563814711964" target="_blank" rel="noopener noreferrer">
                        <FaFacebook className="facebook-bg border-radius" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/superkirana_official/?hl=en" target="_blank" rel="noopener noreferrer">
                        <FaSquareInstagram className="instagram-bg border-radius" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/104161632/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin className="linkdln-bg border-radius" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@Superkirana" target="_blank" rel="noopener noreferrer">
                        <FaYoutube className="youtube-bg border-radius" />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Navbar expand="lg">
        <Container fluid className="navtoggle-tab-view">
          <Navbar.Brand className="phone-view-logo" href="/">
            <img src={Logo} alt="superkirana logo" className="img-fluid" />
          </Navbar.Brand>
          <div className="d-flex">
            <div className="theme-btn me-2 px-3 py-0 brochure-content d-lg-none" onClick={handleShow}>Brochure</div>
            <Navbar.Toggle className="toggle-btn-phn-size" aria-controls={`offcanvasNavbar-expand-lg`}>
              <GiHamburgerMenu />
            </Navbar.Toggle>
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end">
            <Offcanvas.Header closeButton>
              <Nav.Link href="/">
                <img src={Logo} alt="superkirana logo" className="img-fluid" />
              </Nav.Link>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1">
                <Nav.Link href="/the-brand">The Brand</Nav.Link>
                <Nav.Link href="/gifting">Gifting</Nav.Link>
                {/* <Nav.Link href="/why-us">Why us</Nav.Link> */}
                <Nav.Link href="/how-we-work">How we work?</Nav.Link>
                <Nav.Link href="/franchise">Franchise</Nav.Link>
                {/* <Dropdown
                  placement="bottomRight"
                  className="nav-link cursor-pointer d-none d-lg-block"
                  menu={{
                    items: items1,
                  }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Franchise
                      <IoIosArrowDown />
                    </Space>
                  </a>
                </Dropdown> */}
                {/* <NavDropdown
                  title={
                    <>
                      Franchise
                      <IoIosArrowDown />
                    </>
                  }
                  className="d-block d-lg-none"
                  id={`offcanvasNavbarDropdown-expand-lg`}>
                  <NavDropdown.Item href="/market-overview">
                    Market Overview
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/franchise-models">
                    Franchise Models
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/investment-and-financials">
                    Investment & Financials
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/franchise-benefits">
                    Franchise Benefits
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/site-selection">
                    Site Selection
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/success-stories">
                    Success Stories
                  </NavDropdown.Item>
                </NavDropdown> */}
                {/* <Dropdown
                  placement="bottomRight"
                  className="nav-link cursor-pointer d-none d-lg-block"
                  menu={{
                    items: items2,
                  }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Services
                      <IoIosArrowDown />
                    </Space>
                  </a>
                </Dropdown>
                <NavDropdown
                  title={
                    <>
                      Services
                      <IoIosArrowDown />
                    </>
                  }
                  className="d-block d-lg-none"
                  id={`offcanvasNavbarDropdown-expand-lg`}>
                  <NavDropdown.Item href="/franchise-support-and-training">
                    Franchise Support & Training
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/supply-chain-management">
                    Supply Chain Management
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/inventory-management">
                    Inventory Management
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/marketing-and-promotions">
                    Marketing & Promotions
                  </NavDropdown.Item>
                </NavDropdown> */}
                <Nav.Link href="/get-started">Get Started</Nav.Link>
                {/* <Nav.Link href="/partnerships">Partnerships</Nav.Link> */}
                {/* <Nav.Link href="/our-stores">Our Stores</Nav.Link> */}
                <Nav.Link href="/careers">Careers</Nav.Link>
                <Nav.Link href="/contact-us">Contact us</Nav.Link>
                <Nav.Link className="theme-btn" href="javascript:void(0)" onClick={handleShow}>Brochure</Nav.Link>
              </Nav>
              <div className="d-block d-lg-none">
                <div className="mobile-link-box">
                  <h4>Follow Us</h4>
                  <ul className="mobile-sm-links">
                    <li>
                      <a className="facebook-bg" href="https://www.facebook.com/profile.php?id=61563814711964" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a className="instagram-bg" href="https://www.instagram.com/superkirana_official/?hl=en" target="_blank" rel="noopener noreferrer">
                        <FaSquareInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/104161632/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@Superkirana" target="_blank" rel="noopener noreferrer">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                  <h4>Contact Us</h4>
                  <ul className="mobile-contact-links">
                    <li>
                      <a href="mailto:info@mathillsgroup.com">
                        <div className="icon">
                          <IoMail />
                        </div>
                        info@mathillsgroup.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:+919205781326">
                        <div className="icon">
                          <IoIosCall />
                        </div>{" "}
                        +91 92057 81326
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>



      {/* For Brochure Form */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="border-0"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="enquiry-form row">
            <div className="col-12 mb-3">
              <Input
                placeholder="Full Name*"
                name="name"
                value={Enquiry.name}
                onChange={(e) =>
                  setEnquiry({
                    ...Enquiry,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-12 mb-3">
              <Input
                placeholder="Email*"
                name="email"
                value={Enquiry.email}
                onChange={(e) =>
                  setEnquiry({
                    ...Enquiry,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-12 mb-3">
              <Input
                placeholder="Mobile No.*"
                name="mobile"
                value={Enquiry.mobile}
                onChange={(e) => {
                  if (e.target.value.length <= 10)
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value.replace(/\D/g, ""),
                    });
                }}
              />
            </div>
            <div className="col-12">

              <button type="btn" className="theme-btn mb-3 py-2 fs-6" onClick={handleDownload}>
                Download Brochure
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
}
