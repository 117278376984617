// This file is generated by Create React App

// Register a service worker
export function register(config) {
    if ('serviceWorker' in navigator) {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        window.addEventListener('load', () => {
            navigator.serviceWorker.register(swUrl).then(
                (registration) => {
                    console.log('Service Worker registered with scope:', registration.scope);
                },
                (error) => {
                    console.error('Service Worker registration failed:', error);
                }
            );
        });
    }
}

// Unregister a service worker
export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => registration.unregister());
        });
    }
}
