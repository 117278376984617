import InnerBanner from "../Components/InnerBanner/InnerBanner";
import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import Implementation from "../assets/img/how-we-work/Implementation.png";
import InitialConsultation from "../assets/img/how-we-work/Initial-consultation.png";
import StoreAssessment from "../assets/img/how-we-work/Store-Assessment-Planning.png";
import LaunchSupport from "../assets/img/how-we-work/launch-support.png";
import LeftImg from "../assets/img/services/how we work.jpg";

import Initialconsultationbottom from "../assets/img/application/Initialconsultation.png";
import TechnologyTraining from "../assets/img/application/Technology&Training.png";
import launchmarketing from "../assets/img/application/launch.png";
import OngoingSupport from "../assets/img/application/OngoingSupport.png";
import StoreTransformation from "../assets/img/application/StoreTransformation.png";
export default function HowWeWork() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing how-we-work-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 mb-4 mb-lg-0">
              <img src={LeftImg} className="img-fluid mb-2 left-img" alt="How SuperKirana Works" />
              <div className="main-heading">
                <span>How SuperKirana Works</span>
                <h2>Ensures a Smooth and Successful Launch</h2>
              </div>
              <p>
                SuperKirana operates on a franchise model, where store owners
                partner with us to upgrade their existing Kirana stores. The
                process involves a comprehensive assessment of the store's
                current condition, followed by a tailored upgrade plan that
                includes everything from infrastructure improvements to
                technology integration. Our team works closely with the store
                owner to ensure a smooth transition, providing support at every
                step.
              </p>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <div className="work-card">
                    <div className="icon">
                      <img
                        src={InitialConsultation}
                        className="img-fluid"
                        alt="Initial Consultation"
                      />
                    </div>
                    <div className="content">
                      <h4>1. Initial Consultation</h4>
                      <p>
                        We begin by understanding the store owner's needs,
                        challenges, and vision for their store. This
                        consultation helps us tailor our services to match the
                        specific requirements of each store.
                      </p>
                    </div>
                  </div>
                  <div className="work-card">
                    <div className="icon">
                      <img
                        src={StoreAssessment}
                        className="img-fluid"
                        alt="Store Assessment and Planning"
                      />
                    </div>
                    <div className="content">
                      <h4>2. Store Assessment and Planning</h4>
                      <p>
                        Our team conducts a thorough assessment of the store,
                        including its layout, product range, and customer
                        demographics. Based on this assessment, we create a
                        customized upgrade plan that outlines the changes needed
                        to transform the store into a SuperKirana outlet.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-lg-4 pt-lg-3">
                  <div className="work-card">
                    <div className="icon">
                      <img
                        src={Implementation}
                        className="img-fluid"
                        alt="Implementation"
                      />
                    </div>
                    <div className="content">
                      <h4>3. Implementation</h4>
                      <p>
                        Once the plan is approved, we move into the
                        implementation phase. This includes renovating the
                        store's interior, installing new shelving and fixtures,
                        setting up the POS and inventory management systems, and
                        training the staff on new processes.
                      </p>
                    </div>
                  </div>
                  <div className="work-card">
                    <div className="icon">
                      <img
                        src={LaunchSupport}
                        className="img-fluid"
                        alt="Launch and Support"
                      />
                    </div>
                    <div className="content">
                      <h4>4. Launch and Support</h4>
                      <p>
                        After the store is transformed, we assist with a grand
                        re-opening, complete with marketing support to attract
                        customers. Post-launch, we provide ongoing support to
                        ensure the store operates smoothly and continues to
                        thrive.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 transformation-process-section section-spacing-y pb-0">
              <div className="main-heading main-heading-center">
                <span>The Superkirana Transformation</span>
                <h2>From Application to Launch: How Superkirana Supports You</h2>
              </div>
              <div className="transformation-process-box">
                <div className="transformation-process-card">
                  <span>
                    <img
                      src={Initialconsultationbottom}
                      className="img-fluid"
                      alt="Initial Consultation"
                    />
                  </span>
                  <h4>Initial Consultation</h4>
                  <p>
                    Investment: ₹5 Lakhs for branding, layout optimization, and
                    technology upgrades.
                  </p>
                </div>
                <div className="transformation-process-card">
                  <span>
                    <img
                      src={StoreTransformation}
                      className="img-fluid"
                      alt="Store Transformation"
                    />
                  </span>
                  <h4>Store Transformation</h4>
                  <p>
                    Investment: ₹5 Lakhs for branding, layout optimization, and
                    technology upgrades.
                  </p>
                </div>
                <div className="transformation-process-card">
                  <span>
                    <img
                      src={TechnologyTraining}
                      className="img-fluid"
                      alt="Technology & Training"
                    />
                  </span>
                  <h4>Technology & Training</h4>
                  <p>
                    Training for staff ensures operational efficiency and better
                    customer service, leading to a 20% reduction in customer
                    complaints.
                  </p>
                </div>
                <div className="transformation-process-card">
                  <span>
                    <img
                      src={launchmarketing}
                      className="img-fluid"
                      alt="Launch & Marketing"
                    />
                  </span>
                  <h4>Launch & Marketing</h4>
                  <p>
                    An aggressive local marketing campaign leads to a 10% jump
                    in foot traffic and online visibility.
                  </p>
                </div>
                <div className="transformation-process-card">
                  <span>
                    <img
                      src={OngoingSupport}
                      className="img-fluid"
                      alt="Ongoing Support"
                    />
                  </span>
                  <h4>Ongoing Support</h4>
                  <p>
                    Monthly performance evaluations and business optimization
                    reviews ensure continuous growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
