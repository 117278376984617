import InnerBannerImg from "../../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import { FaFilePdf } from "react-icons/fa6";
import Foodpdf from "../../assets/pdf/Festival offers FMCG_Mathills group.pdf";
import NonFoodpdf from "../../assets/pdf/Festival offers non food_Mathills group.pdf";

export default function Gifting() {
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing investment-financials-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>Super Kirana: Corporate Gifting Solutions</span>
                <h2>
                  Personalized Gifting Experiences that Impress and Delight
                </h2>
                <p>
                  At Super Kirana, we believe in transforming everyday moments
                  into memorable experiences through thoughtful and personalized
                  gifting solutions. Our Corporate Gifting service offers
                  curated products that cater to a wide range of tastes and
                  preferences, perfect for every occasion.
                </p>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="main-heading">
                <span>Why Choose Super Kirana for Corporate Gifting?</span>
              </div>
              <ul>
                <li>
                  <p>
                    <b>Customizable Gift Sets:</b>
                    1. From festive hampers to everyday essentials, choose from
                    a variety of curated gift boxes designed to meet your
                    corporate needs.
                  </p>
                </li>
                <li>
                  <p>
                    <b>High-Quality Products:</b>
                    We partner with top-tier brands to ensure that every gift
                    reflects the highest standards of quality.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Bulk Orders Made Easy:</b>
                    Simplified bulk ordering with flexible pricing options for
                    businesses of all sizes.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Eco-Friendly Options:</b>
                    Offer sustainable and environmentally conscious gifts to
                    enhance your corporate social responsibility.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Personalization:</b>
                    Add a personal touch to your gifts with custom messages,
                    logos, or branded packaging that reflects your company’s
                    values.
                  </p>
                </li>
              </ul>
              <div className="main-heading">
                <span>Our Corporate Gifting Range</span>
              </div>
              <ul>
                <li>
                  <p>
                    <b>Festive Gift Boxes:</b>
                    Celebrate the festive season with beautifully packed hampers
                    featuring a mix of gourmet snacks, wellness products, and
                    home essentials.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Office Essentials Kits:</b>
                    Thoughtfully curated kits for employees, including premium
                    stationery, productivity tools, and health-conscious snacks.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Wellness Hampers:</b>
                    Promote well-being in the workplace with gift boxes
                    containing health supplements, herbal teas, and nutritious
                    snacks.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Client Appreciation Gifts:</b>
                    Show your clients that you care with sophisticated and
                    customizable gift sets that leave a lasting impression.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Employee Recognition Gifts:</b>
                    Reward your employees with tokens of appreciation, perfect
                    for anniversaries, promotions, and milestones.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-12 mt-3">
              <strong>
                Don’t Miss Out: Exclusive Festival Offers from Mathills Group!
              </strong>
            </div>
            <div className="col-12 mt-3 ms-2">
              <a target="blank" className="me-4" href={Foodpdf}>
                <FaFilePdf className="pdfcolor" />
              </a>
              <a target="blank" href={NonFoodpdf}>
                <FaFilePdf className="pdfcolor" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="section-spacing get-started-page pt-0 mt-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center mb-4">
                <span>How It Works</span>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 mb-4">
              <div className="get-card">
                <h4>Select a Gift Range</h4>
                <p>
                  Choose from our pre-designed hampers or create a customized
                  set based on your company’s preferences.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 mb-4">
              <div className="get-card">
                <h4>Place Your Order</h4>
                <p>
                  Enjoy hassle-free bulk ordering with competitive pricing,
                  delivered right to your doorstep.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 mb-4">
              <div className="get-card">
                <h4>Personalize Your Gifts</h4>
                <p>
                  Add a unique touch to each gift with branded packaging or
                  personalized messages.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 mb-4">
              <div className="get-card">
                <h4>Timely Delivery</h4>
                <p>
                  We ensure all your gifts are delivered on time, every time.
                </p>
              </div>
            </div>
            <div className="col-12">
              <b>Contact Us</b>
              <p>
                For bulk inquiries and personalized gifting solutions, get in
                touch with our corporate gifting team today at{" "}
                <strong>
                  <a href="mailto:info@mathillsgroup.com">
                    info@mathillsgroup.com
                  </a>{" "}
                </strong>
                or call{" "}
                <strong>
                  {" "}
                  <a href="tel:9205781326">+91-9205781326</a>.
                </strong>{" "}
                Let Super Kirana help you make every corporate occasion
                memorable.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
