import ApplicationSection from "../Components/Home/ApplicationSection/ApplicationSection";
import BenefitsSection from "../Components/Home/BenifitsSection/BenefitsSection";
import BlogSection from "../Components/Home/BlogSection/BlogSection";
import BrandSection from "../Components/Home/BrandSection/BrandSection";
import Header from "../Components/Home/Header/Header";
import ParallaxSection from "../Components/Home/ParallaxSection/ParallaxSection";
import SuccessStories from "../Components/Home/SeccessStories/SuccessStories";
import ServicesSection from "../Components/Home/ServicesSection/ServicesSection";
import Testimonial from "../Components/Home/Testimonial/Testimonial";
import FranchiseSupport from "../Pages/FranciseSupport/Franchisesupport";
export default function HomePage() {
  return (
    <>
      <Header />
      <FranchiseSupport />
      <ServicesSection />
      <BenefitsSection />
      <SuccessStories />
      <BrandSection />
      <ParallaxSection />
      <ApplicationSection />
      <Testimonial />
      <BlogSection />
    </>
  );
}
